import React, { useEffect, useState } from 'react';
import LogoIcon from '../assets/img/header-logo-icon.svg';
import MenuIcon from '../assets/img/burger-menu.svg';
import Logo from '../assets/img/header-logo.svg';
import Key from '../assets/img/header-key.svg';
import Profile from '../assets/img/header-profile.svg';
import Modal from '../components/modal';
import Discord from '../assets/img/discord.svg';
import Twitter from '../assets/img/twitter.svg';
import Btn from '../assets/img/btn.svg';
import { useHistory, useLocation } from 'react-router-dom';
import close from '../assets/img/close-modal.svg';

const HeaderView: React.FC<{
  className?: string;
  setIsModalVisible: (value: boolean) => void;
  isModalVisible: boolean;
  isModalProfile: boolean;
  setModalProfile: (value: boolean) => void;
  renderModalContent: () => React.ReactNode;
  onAuthComplete?: () => void;
  height?: number;
}> = ({
  className,
  renderModalContent,
  onAuthComplete,
  isModalProfile,
  setModalProfile,
  height,
}) => {
  const isAuthorized = localStorage.getItem('token');
  const history = useHistory();

  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

  return (
    <>
      <div className={`v-header ${className}`}>
        <div className="v-header__logo">
          <img
            className="mr-12 v-header__logo-icon"
            height={14}
            src={LogoIcon}
          />
          <div className="v-header__logo-devider mr-12"></div>
          <img className="v-header__logo-text" height={25} src={Logo} />
        </div>
        <div className="v-header__menu only-mobile" onClick={() => setIsMenuOpened(true)}>
          <img src={MenuIcon} />
        </div>
        <div className="v-header__tabs">
          <a
            href="https://discord.gg/ddMSTH9"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className="pointer mr-40" src={Discord} />
          </a>
          <a
            href="https://twitter.com/cryptowanga"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className="pointer mr-40" src={Twitter} />
          </a>
          <a
            href="https://medium.com/cryptoforecast"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className="pointer" src={Btn} />
          </a>
          {isAuthorized ? (
            <div
              onClick={() => setModalProfile(true)}
              className="pointer ml-60 items-center flex"
            >
              <img src={Profile} />
              <a className="v-header__text-account" href="#">
                Account
              </a>
            </div>
          ) : (
            <div
              onClick={() => history.push('/auth')}
              className="pointer ml-60 items-center flex"
            >
              <img src={Key} />
              <a className="v-header__text-account" href="#">
                Log in
              </a>
            </div>
          )}
        </div>
      </div>
      {isModalProfile && isAuthorized && (
        <Modal
          onRequestClose={() => setModalProfile(false)}
          isOpen={isModalProfile}
          contentClassName="modal-content--profile-right"
        >
          <div className="v-header__modal-content v-header__modal-p">
            {renderModalContent()}
          </div>
        </Modal>
      )}

      <Modal
        onRequestClose={() => setIsMenuOpened(false)}
        isOpen={isMenuOpened}
        contentClassName="modal-content--profile-right"
      >
        <div className="v-header__modal-content v-header__modal-p">
          <div
            className="flex flex-col v-profile"
            style={{
              marginTop: `${height}px`,
              height: `calc(100% - ${height}px)`,
            }}
          >
            <div className="v-profile__content">
              <div className="v-profile__title v-profile__spase-between">
                <span></span>
                <img
                  height="44"
                  src={close}
                  onClick={() => setIsMenuOpened(false)}
                />
              </div>

              <div className="v-header__tabs--mobile">
              {isAuthorized ? (
                  <a
                    href="#"
                    onClick={() => setModalProfile(true)}
                    className="pointer items-center flex v-header__text-account"
                  >
                    <div>
                      <img src={Profile} className="pointer mr-40" />
                      <span>Account</span>
                    </div>
                  </a>
                ) : (
                  <a
                    href="#"
                    onClick={() => history.push('/auth')}
                    className="pointer items-center flex v-header__text-account"
                  >
                    <div>
                      <img src={Key} className="pointer mr-40" />
                      Log in
                    </div>
                  </a>
                )}
                <a
                  href="https://discord.gg/ddMSTH9"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="v-header__text-account"
                >
                  <div>
                    <img className="pointer mr-40" src={Discord} />
                    Discord
                  </div>
                </a>
                <a
                  href="https://twitter.com/cryptowanga"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="v-header__text-account"
                >
                  <div>
                    <img className="pointer mr-40" src={Twitter} />
                    <span>Twitter</span>
                  </div>
                </a>
                <a
                  href="https://medium.com/cryptoforecast"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="v-header__text-account"
                >
                  <div>
                    <img className="pointer mr-40" src={Btn} />
                    Medium
                  </div>
                </a>
                
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HeaderView;
