import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Moment } from 'moment'
import { loadStripe } from '@stripe/stripe-js';
import { useCookies } from "react-cookie";

import close from '../../assets/img/close-modal.svg'
import { getProfileInfo } from '../../api/api';

let moment = require('moment');
interface IReg {
    email: string;
    free_license: boolean;
    date: Moment | null;
}

let stripe;
const PUBLIC_KEY = process.env.REACT_APP_STRIPE_PK || ''
const STRIPE_SERVER_URL = process.env.REACT_APP_API || ''

const Sticky: React.FC<{ setClose: () => void }> = ({ setClose }) => {
    const isAuth = localStorage.getItem('token') || null
    const history = useHistory()
    const [values, setValues] = React.useState<IReg>({ email: '', free_license: true, date: null });
    const [isCard, setCard] = React.useState<boolean>(false)
    const [cookies, setCookie] = useCookies(["banner"]);
    const [loading, setLoading] = React.useState<boolean>(true)
    let [cookieArray, setCookieArray] = React.useState({})

    function handleCookie(bannerNamber) {
        let date = new Date()
        date.setDate(date.getDate() + 7)
        changeCookie(bannerNamber, date)
        setCookie("banner", cookieArray, {
            path: "/",
            expires: date
        });
    }

    const changeCookie = (number: number, date: Date) => {
        if (!cookieArray || !cookieArray[values.email]) {
            if (!cookieArray)
                cookieArray = {}
            cookieArray[values.email] = { 1: null, 2: null, 3: null, 4: null }
        }
        cookieArray[values.email][number] = date;
        setCookieArray(cookieArray)
    }

    React.useEffect(() => {
        const init = async () => {
            try {
                setCookieArray(cookies.banner);
                if (isAuth)
                    await getProfileInfo().then(async (data) => {
                        if (data) {
                            setValues(data)
                            if (data.date !== null && !data.free_license && Math.round((moment(data.date) - moment()) / 86400000) < 4)
                                await getCardInfo()
                            else if (Math.round((moment(data.date) - moment()) / 86400000) >= 4)
                                setCard(true)
                        }
                    });
                getStripeAcc()
            } catch (e) {
                console.log(e)
            }
            finally {
                setLoading(false)
                setCard(false)
            }
        }
        init()
    }, [])

    const createCheckoutSession = () => {
        return axios.post(`${STRIPE_SERVER_URL}/payments/checkoutSession/create`)
            .catch((e) => {
                console.log(e)
            })
    };

    const getStripeAcc = async () => {
        stripe = await loadStripe(PUBLIC_KEY);
    }
    const getCardInfo = async () => {
        try {
            await axios.get(`${STRIPE_SERVER_URL}/payments/card`).then(async () => {
                setCard(true)
            })
        } catch (e) {
            console.log(e)
            setCard(false)
        }
    }

    const setBanner = () => {
        let today = new Date()
        if (!isAuth && (!!cookieArray && (!cookieArray[values.email] || new Date(cookieArray[values.email][1]) < today) || !cookieArray))
            return (
                <label>
                    <a href="#" onClick={() => history.push('/registration')}>Sign up</a>&nbsp;and recieve access to 5 predictions 🚀
                </label>
            )
        else if (isAuth && values.date === null && (!!cookieArray && (!cookieArray[values.email] || new Date(cookieArray[values.email][2]) < today) || !cookieArray))
            return (
                <label>
                    Unlock all 140+ predictions with&nbsp;<a href="#" onClick={async () => {
                        await createCheckoutSession().then((data) => {
                            if (data)
                                stripe?.redirectToCheckout({
                                    sessionId: data.data.id
                                });
                        })
                    }}>our membership</a>
                </label>
            )
        else if (isAuth && values.date !== null && values.free_license && (!!cookieArray && (!cookieArray[values.email] || new Date(cookieArray[values.email][3]) < today) || !cookieArray))
            return (
                <label>
                    Your subscription expired
                </label>
            )
        else if (isAuth && values.date !== null && !values.free_license && !isCard && (!!cookieArray && (!cookieArray[values.email] || new Date(cookieArray[values.email][4]) < today) || !cookieArray)) return (
            <label>
                <a href="#" onClick={async () => {
                    await createCheckoutSession().then((data) => {
                        if (data)
                            stripe?.redirectToCheckout({
                                sessionId: data.data.id
                            });
                    })
                }}>Update your payment method</a>
            </label>
        )
        else {
            setClose();
            return
        }
    }

    return (
        <>
            {!loading ?
                <div className={`v-app__baner sticky-inner  ${isAuth && values.date === null || !isAuth ? 'v-app__baner__info' : 'v-app__baner__attention'}`}>
                    {setBanner()}
                    <div>
                        <img
                            onClick={() => {
                                let bannerNamber = !isAuth ? 1 : isAuth && values.date === null ? 2 : isAuth && values.date !== null && values.free_license ? 3 : isAuth && values.date !== null && !values.free_license && !isCard ? 4 : null
                                handleCookie(bannerNamber);
                                setClose()
                            }}
                            className='v-app__baner__close'
                            height='20'
                            src={close}
                        />
                    </div>
                </div> : <div style={{ marginTop: '-3rem' }}></div>}
        </>
    )
}

export default Sticky;
