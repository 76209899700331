import classnames from 'classnames';
import * as React from 'react';
import useIsMobile from '../hooks/isMobile';

interface IData {
  profit: number;
  timestamp: number;
}

const ChartItem: React.FC<{
  data: IData;
  maxValue?: number;
  index: number;
}> = ({ data, maxValue = 100, index }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div
        className={classnames('chart-item', {
          'bg-bullish': data.profit >= 0,
        })}
      >
        <div className="chart-item__date--mobile">
          {data.timestamp}
        </div>
        <div className='chart-item__container-bar'>
          <div
            className={classnames('chart-item__bar', {
              bearish: data.profit < 0,
              bullish: data.profit >= 0,
            })}
            style={{
              width: `${
                maxValue !== 0 ? (Math.abs(data.profit) / maxValue) * 140 : 0
              }px`,
            }}
          />
        </div>
        <div className="chart-item__profit--mobile">{`${
          data.profit > 0 ? '+' : ''
        }${data.profit}%`}</div>
      </div>
    );
  }

  return (
    <div className="chart-item">
      <div className="chart-item__text">{`${data.profit > 0 ? '+' : ''}${
        data.profit
      }%`}</div>
      <div
        className={classnames('chart-item__bar', {
          bearish: data.profit < 0,
          bullish: data.profit >= 0,
        })}
        style={{
          height: `${
            maxValue !== 0 ? (Math.abs(data.profit) / maxValue) * 140 : 0
          }px`,
        }}
      />
      <div className="chart-item__line" />
      <div className="chart-item__x-axis">
        {index % 2 === 0 ? data.timestamp : ''}
      </div>
    </div>
  );
};

const PredictionProfit: React.FC<{
  className?: string;
  style?: React.CSSProperties;
  data?: IData[];
}> = ({ className, style, data = [] }) => {
  if (!data.length) {
    return null;
  }

  return (
    <div className={`chart__wrapper ${className}`} style={style}>
      <div className="chart">
        {data.map((item, index) => (
          <ChartItem
            data={item}
            index={index}
            key={index}
            maxValue={Math.max(...data.map((item) => Math.abs(item.profit)))}
          />
        ))}
      </div>
    </div>
  );
};

export default PredictionProfit;
