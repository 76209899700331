import cn from 'classnames';
import React from 'react';
import * as yup from 'yup';

import '../assets/style/v-auth.sass';
import { registration } from '../api/api';
import logo from '../assets/img/logo-registration.svg'
import close from '../assets/img/close-modal.svg'
import ButtonLoader from '../components/button';
import { useHistory } from 'react-router-dom';
/*
    Content of the user registration window with yup verification
*/
interface IReg {
    email: string;
    password: string;
    confirmPassword: string;
}
const LayoutView: React.FC<{
}> = ({
}) => {
        const [values, setValues] = React.useState<IReg>({ email: "", password: "", confirmPassword: "" });
        const [message, setMessage] = React.useState<{ email: string, password: string, confirmPassword: string, error: string }>({ email: "", password: "", confirmPassword: "", error: "" });
        const [checked, setChecked] = React.useState(false);
        const [loading, setLoading] = React.useState<boolean>(false)
        let history = useHistory();
        let validPassword = yup.object().shape({
            password: yup.string().min(6)
        });

        let validEmail = yup.object().shape({
            email: yup.string().email().min(5),
        });

        const validate = () => {
            if (values.email !== "" && values.password !== "" && values.confirmPassword !== '' && checked)
                return true;
            else {
                return false;
            }
        }

        const error = async () => {
            await setMessage({ email: "", password: "", confirmPassword: "", error: "" })
            if (values.email !== "" && !(validEmail.isValidSync(values))) {
                setMessage({ ...message, email: "Incorrect email" })
            }
            if (values.password !== "" && !validPassword.isValidSync(values)) {
                setMessage({ ...message, password: "Password is too short (minimum is 6 characters)" })
            }
            if (values.confirmPassword !== "" && values.password !== values.confirmPassword) {
                setMessage({ ...message, confirmPassword: "Your password and confirmation password do not match" })
            }
        }

        React.useEffect(() => {
            error()
        }, [values]);

        return (
            <>
                {/* Кнопка закрытия, для быстрого фикса обратно */}
                {/* <button
                    className={'v-modal-auth__btn-close'}
                    onClick={() => setIsModalVisible(false)}
                >
                    <img
                        src={close}
                    />
                        Close
                    </button> */}
                <div className='flex items-center h-full flex-col v-modal-auth'>
                    <div className='flex pointer v-modal-auth__logo' onClick={() => history.push('/')}>
                        <img
                            src={logo}
                        />
                    </div>
                    <div className="flex items-center h-full flex-col justify-center sign-up-container">
                        <h4 className="mb-40">Sign up for an account</h4>
                        <input
                            type='email'
                            placeholder='Enter email'
                            className={!message.email ? 'input-text' : 'input-text input-text__invalid'}
                            value={values.email}
                            onChange={(e) => {
                                setMessage({ ...message, email: '', error: '' })
                                setValues({ ...values, email: e.target.value });
                            }}
                        />
                        {message.email &&
                            <div className='v-modal-auth__error-message'>{message.email}</div>}
                        <input
                            type='password'
                            placeholder='Enter password'
                            className={!message.password ? 'input-text' : 'input-text input-text__invalid'}
                            value={values.password}
                            onChange={(e) => {
                                setMessage({ ...message, password: '', error: '' })
                                setValues({ ...values, password: e.target.value })
                            }}
                        />
                        {message.password &&
                            <div className='v-modal-auth__error-message'>{message.password}</div>}
                        <input
                            type='password'
                            placeholder='Confirm password'
                            className={!message.confirmPassword ? 'input-text' : 'input-text input-text__invalid'}
                            value={values.confirmPassword}
                            formNoValidate
                            onChange={(e) => {
                                setMessage({ ...message, confirmPassword: '', error: '' })
                                setValues({ ...values, confirmPassword: e.target.value })
                            }}
                        />
                        {message.confirmPassword &&
                            <div className='v-modal-auth__error-message'>{message.confirmPassword}</div>}
                        <div className='v-modal-auth__checkbox'>
                            <input type='checkbox' id="check" onChange={() => setChecked(!checked)} />
                            <label htmlFor="check" />
                            <div className='v-modal-auth__text-label'>
                                I have read and agreed with the Cryptoforecast <a target="_blank" href='https://cryptoforecast-api-dev.truemachine.ru/api/v1/termsOfUse'>Terms of Service and Refund Policy</a>
                            </div>
                        </div>
                        <ButtonLoader
                            disabled={loading}
                            opacity={validate() && !loading ? '1' : '0.4'}
                            size={'large'}
                            onClick={async () => {
                                setLoading(true)
                                error()
                                if (validate() && validEmail.isValidSync(values) && validPassword.isValidSync(values) && values.password === values.confirmPassword) {
                                    let response = await registration(values.email, values.password)
                                    if (response === true) {
                                        history.push('/#reg')
                                    }
                                    else
                                        setMessage({ ...message, error: response });
                                }
                                setLoading(false)
                            }}
                            type="button"
                            isLoad={loading}
                            children="Sign up" />
                        {message.error &&
                            <div className='v-modal-auth__error-message'>{message.error}</div>}
                        <div className='line' />
                        <a onClick={() => history.push('/auth')} href="#">Already have an account?</a>
                    </div>
                </div>
            </>
        );
    };

export default LayoutView;
