import cn from 'classnames';
import * as React from 'react';
import lockIcon from '../../assets/img/blocked.svg';

interface IProfitPercentViewProps {
  percent: number;
  reversed?: boolean;
}

const ProfitPercentView: React.FC<
  IProfitPercentViewProps &
    Pick<React.HTMLAttributes<HTMLDivElement>, 'style' | 'className'>
> = ({ percent, reversed = false, ...rest }) => {
  if (percent === -1000) {
    return (
      <div
        {...rest}
        className={cn(
          'v-profit-percent',
          rest.className,
        )}
        style={{backgroundColor: 'rgba(182, 201, 255, 0.4)'}}
      >
        <img src={lockIcon} />
      </div>
    );
  } else {
    return (
      <div
        {...rest}
        className={cn(
          'v-profit-percent',
          {
            'bg-bullish': reversed ? percent < 0 : percent > 0,
            'bg-bearish': reversed ? percent > 0 : percent < 0,
          },
          rest.className,
        )}
      >
        {`${percent > 0 ? '+' : ''}${percent}%`}
      </div>
    );
  }
};

export default ProfitPercentView;
