import cn from 'classnames';
import React from 'react';
import * as yup from 'yup';
import ButtonLoader from '../components/button/index'

import '../assets/style/v-auth.sass';

import { authorization } from '../api/api';
import logo from '../assets/img/logo-registration.svg'
import close from '../assets/img/close-modal.svg'
import { useHistory } from 'react-router-dom';

/*
    Content of the user authorization window with yup verification
*/

interface IReg {
    email: string;
    password: string;
}

const LayoutView: React.FC = () => {
        const [values, setValues] = React.useState<IReg>({ email: "", password: "" });
        const [message, setMessage] = React.useState<{ localError: string, error: string }>({ localError: "", error: "" });
        const [loading, setLoading] = React.useState<boolean>(false)
        let history = useHistory();

        let validPassword = yup.object().shape({
            password: yup.string().min(6)
        });

        let validEmail = yup.object().shape({
            email: yup.string().email().min(5),
        });

        const error = async () => {
            setMessage({ localError: "", error: "" })
            if (values.email !== "" && !(validEmail.isValidSync(values))) {
                setMessage({ ...message, localError: "Incorrect login or password" })
            }
            else if (values.password !== "" && !validPassword.isValidSync(values)) {
                setMessage({ ...message, localError: "Incorrect login or password" })
            }
        }

        React.useEffect(() => {
            error()
        }, [values]);

        return (
            <>
                {/* Кнопка закрытия, для быстрого фикса обратно */}
                {/* <button
                    className={'v-modal-auth__btn-close'}
                    onClick={() => setIsModalVisible(false)}
                >
                    <img
                        src={close}
                    />
                        Close
                    </button> */}
                <div className="flex items-center flex-col v-modal-auth">
                    <div className="flex pointer v-modal-auth__logo" onClick={() => history.push('/')}>
                        <img
                            src={logo}
                        />
                    </div>
                    <div className="flex items-center h-full flex-col justify-center">
                        <h4 className="mb-40">Log into your account</h4>
                        <input
                            type='email'
                            placeholder='Enter email'
                            className={validEmail.isValidSync(values) ? 'input-text' : 'input-text input-text__invalid mb-20'}
                            value={values.email}
                            onChange={(e) => {
                                setMessage({ ...message, localError: "", error:'' })
                                setValues({ ...values, email: e.target.value });
                            }}
                        />
                        <input
                            type='password'
                            placeholder='Enter password'
                            className={cn(validPassword.isValidSync(values) ? 'input-text' : 'input-text input-text__invalid', message.localError ? 'mb-0' : 'mb-20')}
                            value={values.password}
                            onChange={(e) => {
                                setMessage({ ...message, localError: "", error:"" })
                                setValues({ ...values, password: e.target.value })
                            }}
                        />
                        {message.localError &&
                            <div className='v-modal-auth__error-message'>{message.localError}</div>}
                        <ButtonLoader
                            disabled={loading}
                            opacity={values.email != '' && values.password != '' && !loading ? '1' : '0.4'}
                            size={'large'}
                            onClick={async () => {
                                setLoading(true)
                                error()
                                if (validPassword.isValidSync(values) && validEmail.isValidSync(values)) {
                                    let response = await authorization(values.email, values.password)
                                    if (response === true) {
                                        history.push('/')
                                    }
                                    else
                                        setMessage({ ...message, error: response });
                                }
                                setLoading(false)
                            }} 
                            type="button"
                            isLoad={loading}
                            children="Log in" />
                        {message.error &&
                            <div className='v-modal-auth__error-message'>{message.error}</div>}
                        <div className='line' />
                        <div>
                            <a href="#" onClick={() => history.push('/forgetРassword')} className='mr-25'>Forget your password?</a>
                            <a href="#" onClick={() => history.push('/registration')}>Sign up for an account</a>
                        </div>
                    </div>
                </div>
            </>
        );
    };

export default LayoutView;
