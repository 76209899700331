/* eslint-disable sonarjs/no-duplicate-string */
import classnames from 'classnames';
import * as React from 'react';
import DifferenceStrength from '../components/difference-strength/v-difference-strength';
import {
  TableHeaderView,
  TableHeaderRowView,
} from '../components/table/v-table';
import iOk from '../assets/img/ok.svg';
import iClose from '../assets/img/close.svg';
import moment from 'moment';
import {
  SortTypeEnum,
  PredictionHistoryEnum,
  predictionHistoryTitles,
} from '../api/enums';
import { debounce } from 'lodash';
import { ISort } from './v-history';

const headerItems = [
  [
    {
      key: PredictionHistoryEnum.timestamp,
      title: predictionHistoryTitles[PredictionHistoryEnum.timestamp],
    },
    {
      key: PredictionHistoryEnum.predictionStrength,
      title: predictionHistoryTitles[PredictionHistoryEnum.predictionStrength],
      className: 'text-center',
    },
    {
      title: 'Prediction data validation',
      colSpan: 2,
      className: 'text-center',
    },
    {
      key: PredictionHistoryEnum.profit,
      title: predictionHistoryTitles[PredictionHistoryEnum.profit],
      className: 'text-center',
    },
    {
      title: predictionHistoryTitles[PredictionHistoryEnum.predictionRate],
      className: 'text-center',
    },
    {
      title: predictionHistoryTitles[PredictionHistoryEnum.accuracy],
      className: 'text-right',
    },
  ],
  [
    { colSpan: 2 },
    { className: 'text-center', title: 'Start' },
    { className: 'text-center', title: 'End' },
    { colSpan: 3 },
  ],
];

const TableHeader: React.FC<{
  items: any[];
  setSort: (val: ISort) => void;
  sort?: ISort;
}> = ({ items, sort, setSort }) => {
  const handleClick = React.useCallback(
    debounce(
      (sortBy: string, orderBy: string = SortTypeEnum.DESC) =>
        setSort({ sortBy, orderBy }),
      300,
    ),
    [],
  );
  const { DESC, ASC } = SortTypeEnum;

  return (
    <TableHeaderView>
      {items.map((row, idx) => (
        <tr
          key={`table-row-${idx}`}
          className={
            items.length - 1 !== idx ? 'table__header-bordered' : undefined
          }
        >
          {row.map(({ key, title, className, ...rest }, index) => (
            <TableHeaderRowView
              {...rest}
              key={`table-header-${index}`}
              className={classnames(
                className,
                {
                  pointer: key,
                },
                {
                  'v-prediction-screener__selected': key
                    ? sort?.sortBy === key
                    : false,
                },
                {
                  'v-prediction-screener__triangle-desc': key
                    ? sort?.sortBy === key && sort?.orderBy === DESC
                    : false,
                },
                {
                  'v-prediction-screener__triangle-asc': key
                    ? sort?.sortBy === key && sort?.orderBy === ASC
                    : false,
                },
              )}
              {...(key && {
                onClick: () =>
                  handleClick(
                    key,
                    sort?.sortBy === key && sort?.orderBy === DESC ? ASC : DESC,
                  ),
              })}
            >
              <span className="relative">{title}</span>
            </TableHeaderRowView>
          ))}
        </tr>
      ))}
    </TableHeaderView>
  );
};

const TableItem: React.FC<{ data: any }> = ({ data }) => {
  return (
    <tr>
      <td>{moment(data.timestamp).format('DD.MM.YYYY')}</td>
      <td style={{ width: '165px' }}>
        {data.predictionStrength !== undefined ? (
          <DifferenceStrength
            type={
              data.predictionStrength === 0
                ? 'neutral'
                : data.predictionStrength > 0
                ? 'bullish'
                : 'bearish'
            }
            percent={Math.abs(data.predictionStrength)}
          />
        ) : (
          <DifferenceStrength type="pending" />
        )}
      </td>
      <td align="center">{data.predictionStart}</td>
      <td align="center">{data.predictionEnd}</td>
      <td align="center">
        <div
          style={{
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className={classnames({
            'bg-bullish': data.profit > 0,
            'bg-bearish': data.profit < 0,
          })}
        >
          {data.profit !== 0
            ? `${data.profit > 0 ? '+' : ''}${
                Math.round(data.profit * 100) / 100
              }%`
            : ''}
        </div>
      </td>
      <td align="center">{data.predictionRate}%</td>
      <td align="right">
        {data.accuracy !== null ? (
          data.accuracy ? (
            <img src={iOk} />
          ) : (
            <img src={iClose} />
          )
        ) : null}
      </td>
    </tr>
  );
};

const PredictionView: React.FC<{ data?: any[] } & any> = ({
  data = [],
  sort,
  setSort,
}) => {
  return (
    <div className="v-prediction-screener_container">
    <table className="table" style={{ width: '100%' }}>
      <TableHeader items={headerItems} sort={sort} setSort={setSort} />
      <tbody>
        {data.map((item, index) => (
          <TableItem key={index} data={item} />
        ))}
      </tbody>
    </table>
    </div>
  );
};

export default PredictionView;
