/* eslint-disable @typescript-eslint/camelcase */
import cn from 'classnames';
import * as React from 'react';
import Title from '../components/typography/Title';
import Paragraph from '../components/typography/Paragraph';
import Text from '../components/typography/Text';
import TotalProfitView from '../components/v-total-profit';
import LayoutView from './v-layout';
import PredictionProfit from './v-prediction-profit';
import PredictionScreener from './v-prediction-screener';
import {
  getPredictionScreenerData,
  getTotalProfitStatistics,
} from '../api/api';
import { useLocation } from 'react-router-dom';
import * as qs from 'qs';
import SubmitButton from '../components/submit-button';
import Modal from '../components/modal';
import Collapse from '../components/collapse';
import Checkbox from '../components/checkbox';
import Input, { handleInputChange } from '../components/input';
import {
  PredictionScreenerEnum,
  predictionScreenerTitles,
  predictionScreenerReqKeys,
  tooltipTexts,
} from '../api/enums';
import { omit, pickBy } from 'lodash';
import settingsIcon from '../assets/img/settings.svg';
import questionIcon from '../assets/img/question.svg';
import exitIcon from '../assets/img/exit.svg';
import { noop, isEmpty, isUndefined, isNull } from 'lodash';
import { formateTimestamp } from '../api/helpers';
import PredictionTimer from '../components/prediction-timer';
import Tooltip from '../components/tooltip';
import Sticky from '../components/sticky'

export const PredictionStatisticHeader: React.FC<{
  title: string;
  description: string;
  predictions?: string[];
  periods?: string[];
  profits?: string[];
  className?: string;
  activePrediction?: string;
  activePeriod?: string;
  activeProfit?: string;
  onClickPeriod?: (period: string) => void;
  onClickPrediction?: (prediction: string) => void;
  onClickProfit?: (profit: string) => void;
}> = ({
  title,
  description,
  predictions,
  periods,
  profits,
  className,
  activePrediction = 'ALL',
  activePeriod = 'Days',
  activeProfit = 'ALL',
  onClickProfit = noop,
  onClickPeriod = noop,
  onClickPrediction = noop,
}) => {
    const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);

    return (
      <div className={className}>
        <div className="v-landing__title-wrapper">
          <Title level={2} className="v-landing__title mb-16">
            {title}
          </Title>
          <img
              width={29}
              height={29}
              className="ml-14 pointer only-mobile"
              src={settingsIcon}
              onClick={() => setIsModalVisible(true)}
            />
        </div>
        <Paragraph className="mb-30">{description}</Paragraph>
        <div className="flex flex-baseline prediction-statistic-filter only-desktop">
          {!isEmpty(predictions) && (
            <div className="flex mr-50 prediction-statistic-filter__container">
              <Text className="uppercase font-bold mr-30">Prediction:</Text>
              
              {predictions?.map((prediction, idx) => (
                <button
                  key={`prediction-statistic-header-${idx}`}
                  className={cn('pointer', 'bg-transparent', 'border-0', {
                    'mr-30': idx !== predictions.length - 1,
                    'active-choise': prediction === activePrediction,
                  })}
                  onClick={() => onClickPrediction(prediction)}
                >
                  {
                    {
                      Weak: 'Weak < 25%',
                      Normal: 'Normal',
                      Strong: 'Strong > 75%',
                      ALL: 'ALL',
                    }[prediction]
                  }
                </button>
              ))}
            </div>
          )}
          {!isEmpty(predictions) && (
            <div className="flex flex-baseline mr-50 prediction-statistic-filter__container">
              <Text className="uppercase font-bold mr-30 ">Period:</Text>
              {periods?.map((period, idx) => (
                <button
                  key={`prediction-statistic-header-${idx}`}
                  className={cn('pointer', 'bg-transparent', 'border-0', {
                    'mr-30': idx !== periods.length - 1,
                    'active-choise': period === activePeriod,
                  })}
                  onClick={() => onClickPeriod(period)}
                >
                  {period}
                </button>
              ))}
            </div>
          )}
          {!isEmpty(profits) && (
            <div className="flex flex-baseline prediction-statistic-filter__container">
              <Text className="uppercase font-bold mr-30">Trade Position:</Text>
              {profits?.map((profit, idx) => (
                <button
                  key={`prediction-statistic-header-${idx}`}
                  className={cn('pointer', 'bg-transparent', 'border-0', {
                    'mr-30': idx !== profits.length - 1,
                    'active-choise': profit === activeProfit,
                  })}
                  onClick={() => onClickProfit(profit)}
                >
                  {profit}
                </button>
              ))}
            </div>
          )}
        </div>
        <Modal
        onRequestClose={() => {
          setIsModalVisible(false);
        }}
        isOpen={isModalVisible}
      >
        <SubmitButton
          header={() => (
            <div className="v-currency-overview__text-wrapper">
              <img

                className="pointer v-currency-overview__close-icon"
                src={exitIcon}
                onClick={() => setIsModalVisible(false)}
              />
              <Title
                level={1}
                className="v-currency-overview__modal-title ml-20"
              >
                Filters
              </Title>
            </div>
          )}
          render={() => (
            <div className="flex flex-baseline prediction-statistic-filter">
          {!isEmpty(predictions) && (
            <div className="flex mr-50 prediction-statistic-filter__container">
              <Text className="uppercase font-bold mr-30">Prediction:</Text>
              
              {predictions?.map((prediction, idx) => (
                <button
                  key={`prediction-statistic-header-${idx}`}
                  className={cn('pointer', 'bg-transparent', 'border-0', {
                    'mr-30': idx !== predictions.length - 1,
                    'active-choise': prediction === activePrediction,
                  })}
                  onClick={() => onClickPrediction(prediction)}
                >
                  {
                    {
                      Weak: 'Weak < 25%',
                      Normal: 'Normal',
                      Strong: 'Strong > 75%',
                      ALL: 'ALL',
                    }[prediction]
                  }
                </button>
              ))}
            </div>
          )}
          {!isEmpty(predictions) && (
            <div className="flex flex-baseline mr-50 prediction-statistic-filter__container">
              <Text className="uppercase font-bold mr-30 ">Period:</Text>
              {periods?.map((period, idx) => (
                <button
                  key={`prediction-statistic-header-${idx}`}
                  className={cn('pointer', 'bg-transparent', 'border-0', {
                    'mr-30': idx !== periods.length - 1,
                    'active-choise': period === activePeriod,
                  })}
                  onClick={() => onClickPeriod(period)}
                >
                  {period}
                </button>
              ))}
            </div>
          )}
          {!isEmpty(profits) && (
            <div className="flex flex-baseline prediction-statistic-filter__container">
              <Text className="uppercase font-bold mr-30">Trade Position:</Text>
              {profits?.map((profit, idx) => (
                <button
                  key={`prediction-statistic-header-${idx}`}
                  className={cn('pointer', 'bg-transparent', 'border-0', {
                    'mr-30': idx !== profits.length - 1,
                    'active-choise': profit === activeProfit,
                  })}
                  onClick={() => onClickProfit(profit)}
                >
                  {profit}
                </button>
              ))}
            </div>
          )}
        </div>
          )}
          onSubmit={() => {
            // setMetrics(modalState);
            setIsModalVisible(false);
          }}
        >
          Apply
        </SubmitButton>
      </Modal>
      </div>
    );
  };

const MetricField: React.FC<{
  fieldKey: PredictionScreenerEnum;
  disabled?: boolean;
  metric: { [key: string]: any };
  limits: { min: string | number; max: string | number };
  setMetrics: (val: any) => void;
  className?: string;
}> = ({
  metric = {},
  setMetrics,
  fieldKey,
  className,
  limits,
  disabled = false,
}) => {
    const min = predictionScreenerReqKeys[fieldKey].min;
    const max = predictionScreenerReqKeys[fieldKey].max;

    return (
      <div className={`metrics-field ${className}`}>
        <div className="metrics-field__type">
          <div className="metrics-field__type-name">
            <Tooltip>
              {tooltipTexts[fieldKey]?.text && (
                <div>{tooltipTexts[fieldKey]?.text}</div>
              )}{' '}
              {tooltipTexts[fieldKey]?.desc && (
                <div style={{ marginTop: '24px', fontStyle: 'italic' }}>
                  {tooltipTexts[fieldKey]?.desc}
                </div>
              )}{' '}
            </Tooltip>
            <span className="ml-10">{predictionScreenerTitles(fieldKey)}</span>
          </div>
          <Checkbox
            disabled={disabled}
            onChange={(e) => {
              e.persist();
              setMetrics((state) => ({
                ...state,
                [fieldKey]: {
                  ...metric,
                  checked: !metric?.checked,
                  ...(!metric?.checked ? { [min]: '', [max]: '' } : {}),
                },
              }));
            }}
            checked={metric?.checked}
          />
        </div>
        <div className="metrics-field__filters">
          <Input
            disabled={disabled || !metric?.checked}
            value={metric[min]}
            placeholder={
              !isUndefined(limits.min) && !isNull(limits.min)
                ? ` ${limits.min}`
                : undefined
            }
            onChange={(e) => {
              e.persist();
              setMetrics((state) => ({
                ...state,
                [fieldKey]: {
                  ...metric,
                  [min]: handleInputChange(metric[min], e.target.value),
                },
              }));
            }}
            className="metrics-field__filters-control"
          />
        to
        <Input
            disabled={disabled || !metric?.checked}
            value={metric[max]}
            placeholder={
              !isUndefined(limits.max) && !isNull(limits.max)
                ? ` ${limits.max}`
                : undefined
            }
            className="metrics-field__filters-control"
            onChange={(e) => {
              e.persist();
              setMetrics((state) => ({
                ...state,
                [fieldKey]: {
                  ...metric,
                  [max]: handleInputChange(metric[max], e.target.value),
                },
              }));
            }}
          />
        </div>
      </div>
    );
  };
const PeriodField: React.FC<{
  fieldKey: string;
  disabled?: boolean;
  metric: { [key: string]: boolean };
  setMetrics: (val: any) => void;
  className?: string;
}> = ({ metric, setMetrics, fieldKey, className, disabled = false }) => {
  return (
    <div className={`metrics-field ${className}`}>
      <div className="metrics-field__type">
        <div className="metrics-field__type-name">
          <Tooltip>
            {tooltipTexts['predictionProfitBTC']?.text && (
              <div>{tooltipTexts['predictionProfitBTC']?.text}</div>
            )}{' '}
            {tooltipTexts['predictionProfitBTC']?.desc && (
              <div style={{ marginTop: '24px', fontStyle: 'italic' }}>
                {tooltipTexts['predictionProfitBTC']?.desc}
              </div>
            )}{' '}
          </Tooltip>
          <span className="ml-10">Prediction Profit (BTC)</span>
        </div>
        <Checkbox
          disabled={disabled}
          onChange={(e) => {
            e.persist();
            setMetrics((state) => ({
              ...state,
              [fieldKey]: { ...metric, checked: !metric?.checked },
            }));
          }}
          checked={metric?.checked}
        />
      </div>
      <div className="flex ml-8">
        {Object.entries(metric)
          .filter((key) => key[0] !== 'checked')
          .map((field, idx) => (
            <div className="flex mr-10" key={`period-field-${idx}`}>
              <Checkbox
                disabled={disabled}
                onChange={(e) => {
                  e.persist();
                  setMetrics((state) => ({
                    ...state,
                    [fieldKey]: { ...metric, [field[0]]: !field[1] },
                  }));
                }}
                checked={field[1]}
              />
              <Text className="ml-8">{predictionScreenerTitles(field[0])}</Text>
            </div>
          ))}
      </div>
    </div>
  );
};

const initialPredictionFilterState = {
  [PredictionScreenerEnum.priceUSD]: {
    checked: false,
  },
  [PredictionScreenerEnum.priceBTC]: {
    checked: true,
  },
  [PredictionScreenerEnum.dailyChangeUSD]: {
    checked: false,
  },
  [PredictionScreenerEnum.dailyChangeBTC]: {
    checked: true,
  },
  [PredictionScreenerEnum.profitMonthsCount]: {
    checked: true,
  },
  [PredictionScreenerEnum.dailyPredictionProfitUSD]: {
    checked: true,
  },
  [PredictionScreenerEnum.dailyPredictionStrength]: {
    checked: true,
  },
  [PredictionScreenerEnum.dailyPredictionRate]: {
    checked: true,
  },
  [PredictionScreenerEnum.coinMarketCapRating]: {
    checked: true,
  },
  [PredictionScreenerEnum.marketCapUSD]: {
    checked: false,
  },
  [PredictionScreenerEnum.dailyPredictionProfitUSD]: {
    checked: false,
  },
  [PredictionScreenerEnum.cryptoindexRating]: {
    checked: false,
  },
  predictionProfit: {
    checked: true,
    [PredictionScreenerEnum.dailyPredictionProfit]: true,
    [PredictionScreenerEnum.monthlyPredictionProfit]: true,
    [PredictionScreenerEnum.quarterPredictionProfit]: false,
    [PredictionScreenerEnum.halfYearPredictionProfit]: false,
  },
  [PredictionScreenerEnum.initialPriceVSCurrent]: {
    checked: false,
  },
};

const Landing: React.FC = () => {
  const [showAll, setShowAll] = React.useState(false);
  const [predictionScreenerData, setPredictionScreenerData] = React.useState<
    any[]
  >();

  const [
    predictionScreenerLimits,
    setPredictionScreenerLimits,
  ] = React.useState<{ [key: string]: any }>();
  const [searchByName, setSearchByName] = React.useState<string>();

  const [totalProfit, setTotalProfit] = React.useState<any[]>();

  const [totalProfitParams, setTotalProfitParams] = React.useState<{
    period: string;
    prediction: string;
    profit: string;
  }>({
    period: 'Days',
    prediction: 'ALL',
    profit: 'ALL',
  });
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);

  const [metrics, setMetrics] = React.useState<any>(
    JSON.parse(localStorage.getItem('predictionScreener') as any) ||
    initialPredictionFilterState,
  );
  const [modalState, setModalState] = React.useState<any>(metrics);

  const {
    priceUSD,
    priceBTC,
    dailyChangeUSD,
    dailyChangeBTC,
    marketCapUSD,
    dailyPredictionProfitUSD,
    cryptoindexRating,
    dailyPredictionStrength,
    dailyPredictionRate,
    predictionProfit,
    profitMonthsCount,
    initialPriceVSCurrent,
  } = modalState;

  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  const predictionScreenerFilters = React.useMemo(() => {
    const filterSelectedFields = Object.values(
      omit(metrics, ['predictionProfit']),
    ).filter(({ checked }: any) => Boolean(checked));
    const filters = Object.assign({}, ...filterSelectedFields);

    return pickBy(
      omit(
        {
          ...filters,
          search_name: searchByName,
          sort_by: search.sortBy,
          sort_type: search.orderBy,
        },
        ['checked'],
      ),
    );
  }, [metrics, search.sortBy, search.orderBy, searchByName]);

  React.useEffect(() => {
    getPredictionScreenerData(predictionScreenerFilters).then(
      ({ items, limits }) => {
        setPredictionScreenerData(items);
        if (isEmpty(predictionScreenerLimits)) {
          setPredictionScreenerLimits(limits);
        }
      },
    );
    const intervalId = setInterval(() => {
      getPredictionScreenerData(predictionScreenerFilters).then(({ items }) =>
        setPredictionScreenerData(items),
      );
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, [predictionScreenerFilters]);

  React.useEffect(() => {
    getTotalProfitStatistics(totalProfitParams)
      .then((res) =>
        formateTimestamp(res)(
          totalProfitParams.period === 'Days' ? 'DD.MM' : 'MM.YY',
        ),
      )
      .then(setTotalProfit);
  }, [totalProfitParams]);

  React.useEffect(() => {
    localStorage.setItem('predictionScreener', JSON.stringify(metrics));
  }, [metrics]);

  const [isSticky, setSticky] = React.useState(false);
  const [isBaner, setBaner] = React.useState(true);
  const ref = React.useRef<HTMLDivElement>(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref?.current?.getBoundingClientRect()?.top <= 0);
    }
  };
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <div className="v-app">
      {isBaner &&
        <div className={`sticky-wrapper${isSticky ? ' sticky' : ''}`} ref={ref}>
          <Sticky setClose={() => setBaner(false)} />
        </div>
      }
      <Modal
        onRequestClose={() => {
          setIsModalVisible(false);
        }}
        isOpen={isModalVisible}
      >
        <SubmitButton
          header={() => (
            <div className="v-currency-overview__text-wrapper">
              <img

                className="pointer v-currency-overview__close-icon"
                src={exitIcon}
                onClick={() => setIsModalVisible(false)}
              />
              <Title
                level={1}
                className="v-currency-overview__modal-title ml-20"
              >
                Filters
              </Title>
            </div>
          )}
          render={() => (
            <Collapse
              initialOpened={true}
              Header={() => <Title level={2}>Standart metrics</Title>}
            >
              <MetricField
                className="mt-14"
                fieldKey={PredictionScreenerEnum.cryptoindexRating}
                metric={cryptoindexRating}
                setMetrics={setModalState}
                limits={{
                  min: predictionScreenerLimits?.min_cryptoindexRating,
                  max: predictionScreenerLimits?.max_cryptoindexRating,
                }}
              />
              <MetricField
                className="mt-14"
                fieldKey={PredictionScreenerEnum.priceUSD}
                metric={priceUSD}
                setMetrics={setModalState}
                limits={{
                  min: predictionScreenerLimits?.min_priceUSD,
                  max: predictionScreenerLimits?.max_priceUSD,
                }}
              />
              <MetricField
                className="mt-14"
                fieldKey={PredictionScreenerEnum.priceBTC}
                metric={priceBTC}
                setMetrics={setModalState}
                limits={{
                  min: predictionScreenerLimits?.min_priceBTC,
                  max: predictionScreenerLimits?.max_priceBTC,
                }}
              />
              <MetricField
                className="mt-14"
                fieldKey={PredictionScreenerEnum.dailyChangeUSD}
                metric={dailyChangeUSD}
                setMetrics={setModalState}
                limits={{
                  min: predictionScreenerLimits?.min_dailyChangeUSD,
                  max: predictionScreenerLimits?.max_dailyChangeUSD,
                }}
              />
              <MetricField
                className="mt-14"
                fieldKey={PredictionScreenerEnum.dailyChangeBTC}
                metric={dailyChangeBTC}
                setMetrics={setModalState}
                limits={{
                  min: predictionScreenerLimits?.min_dailyChangeBTC,
                  max: predictionScreenerLimits?.max_dailyChangeBTC,
                }}
              />
              <MetricField
                className="mt-14"
                fieldKey={PredictionScreenerEnum.marketCapUSD}
                metric={marketCapUSD}
                setMetrics={setModalState}
                limits={{
                  min: predictionScreenerLimits?.min_marketCapUSD,
                  max: predictionScreenerLimits?.max_marketCapUSD,
                }}
              />
              <MetricField
                className="mt-14"
                fieldKey={PredictionScreenerEnum.dailyPredictionRate}
                metric={dailyPredictionRate}
                setMetrics={setModalState}
                limits={{
                  min: predictionScreenerLimits?.min_dailyPredictionRate,
                  max: predictionScreenerLimits?.max_dailyPredictionRate,
                }}
              />
              <MetricField
                className="mt-14"
                fieldKey={PredictionScreenerEnum.dailyPredictionStrength}
                metric={dailyPredictionStrength}
                setMetrics={setModalState}
                limits={{
                  min: predictionScreenerLimits?.min_dailyPredictionStrength,
                  max: predictionScreenerLimits?.max_dailyPredictionStrength,
                }}
              />
              <MetricField
                className="mt-14"
                fieldKey={PredictionScreenerEnum.initialPriceVSCurrent}
                metric={initialPriceVSCurrent}
                setMetrics={setModalState}
                limits={{
                  min: predictionScreenerLimits?.min_initialPriceVSCurrent,
                  max: predictionScreenerLimits?.max_initialPriceVSCurrent,
                }}
              />
              <MetricField
                className="mt-14"
                fieldKey={PredictionScreenerEnum.dailyPredictionProfitUSD}
                metric={dailyPredictionProfitUSD}
                setMetrics={setModalState}
                limits={{
                  min: predictionScreenerLimits?.min_dailyPredictionProfitUSD,
                  max: predictionScreenerLimits?.max_dailyPredictionProfitUSD,
                }}
              />
              <PeriodField
                className="mt-14"
                fieldKey="predictionProfit"
                metric={predictionProfit}
                setMetrics={setModalState}
              />
              <div className="metrics-field mt-14">
                <div className="metrics-field__type">
                  <div className="metrics-field__type-name">
                    <Tooltip>
                      {tooltipTexts['profitMonthsCount']?.text && (
                        <div>{tooltipTexts['profitMonthsCount']?.text}</div>
                      )}{' '}
                      {tooltipTexts['profitMonthsCount']?.desc && (
                        <div style={{ marginTop: '24px', fontStyle: 'italic' }}>
                          {tooltipTexts['profitMonthsCount']?.desc}
                        </div>
                      )}{' '}
                    </Tooltip>
                    <span className="ml-10">
                      {predictionScreenerTitles('profitMonthsCount')}
                    </span>
                  </div>
                  <Checkbox
                    onChange={(e) => {
                      e.persist();
                      setModalState((state) => ({
                        ...state,
                        ['profitMonthsCount']: {
                          ...profitMonthsCount,
                          checked: !profitMonthsCount.checked,
                        },
                      }));
                    }}
                    checked={profitMonthsCount.checked}
                  />
                </div>
                <div className="metrics-field__filters">
                  <select
                    disabled={!profitMonthsCount.checked}
                    className="metrics-field__filters-control input pointer"
                    value={predictionScreenerReqKeys[profitMonthsCount]}
                    defaultValue={
                      profitMonthsCount[
                      predictionScreenerReqKeys['profitMonthsCount']
                      ]
                    }
                    onChange={(e) => {
                      e.persist();
                      setModalState((state) => ({
                        ...state,
                        ['profitMonthsCount']: {
                          ...profitMonthsCount,
                          [predictionScreenerReqKeys[
                            'profitMonthsCount'
                          ]]: handleInputChange(
                            profitMonthsCount.min,
                            e.target.value,
                          ),
                        },
                      }));
                    }}
                  >
                    <option value={undefined}>--</option>
                    {[
                      '1',
                      '2',
                      '3',
                      '4',
                      '5',
                      '6',
                      '7',
                      '8',
                      '9',
                      '10',
                      '11',
                      '12',
                    ].map((i) => (
                      <option key={`profit-mounth-select-${i}`} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </Collapse>
          )}
          onSubmit={() => {
            setMetrics(modalState);
            setIsModalVisible(false);
          }}
        >
          Apply
        </SubmitButton>
      </Modal>
      <LayoutView
        className={cn('v-app__layout')}
        onAuthComplete={() => {
          setMetrics({ ...metrics });
        }}
        height={isBaner && ref.current?.clientHeight || 0}
      >
        <div className="mb-30">
          <div className="v-landing__title-wrapper">
            <Title level={2} className="v-landing__title" style={{ margin: 0 }}>
              Prediction Screener
            </Title>
            <img
              width={29}
              height={29}
              className="ml-14 pointer"
              src={settingsIcon}
              onClick={() => setIsModalVisible(true)}
            />
          </div>
          <PredictionTimer className="mt-14" />
        </div>
        <div className='v-prediction-screener_container'>
        {predictionScreenerData && (
          <PredictionScreener
            {...{ searchByName, setSearchByName, metrics }}
            data={
              predictionScreenerData &&
              (showAll
                ? predictionScreenerData
                : predictionScreenerData.slice(0, 10))
            }
          />
        )}
        {predictionScreenerData && predictionScreenerData.length > 3 ? (
          <>
            {!showAll && (
              <div style={{ fontSize: '18px', fontWeight: 'bold' }}>...</div>
            )}
            <button
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                background: 'none',
                border: 'none',
                outline: 'none',
                padding: 0,
                cursor: 'pointer',
                marginBottom: '15px',
              }}
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? 'Show top 10 assets' : 'Show all assets'}
            </button>
          </>
        ) : null}
        </div>
        <PredictionStatisticHeader
          className="mb-30 no-mb-mobile"
          title="Prediction Profit Statistics"
          description="Summary of profitability statistics for predictions on all assets over the period"
          predictions={['Weak', 'Normal', 'Strong', 'ALL']}
          periods={['Days', 'Months']}
          profits={['Bearish', 'Bullish', 'ALL']}
          activePeriod={totalProfitParams.period}
          activePrediction={totalProfitParams.prediction}
          activeProfit={totalProfitParams.profit}
          onClickPeriod={(period) =>
            setTotalProfitParams((s) => ({ ...s, period }))
          }
          onClickPrediction={(prediction) =>
            setTotalProfitParams((s) => ({ ...s, prediction }))
          }
          onClickProfit={(profit) =>
            setTotalProfitParams((s) => ({ ...s, profit }))
          }
        />
        <div
          className='prediction-profit-container'
        >
          {totalProfit && (
            <>
              <PredictionProfit style={{ flexGrow: 1 }} data={totalProfit} />
              <TotalProfitView
                profit={totalProfit.reduce(
                  (memo, item) => memo + item.profit,
                  0,
                )}
                per={`${
                  totalProfit.length
                  } ${totalProfitParams.period.toLowerCase()}`}
              />
            </>
          )}
        </div>
      </LayoutView>
    </div>
  );
};

export default Landing;
