import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import IViewProps from '../interfaces/i-view';

import HeaderView from './v-header';
import RegistrationView from './v-registration'
import AuthView from './v-auth'
import ResetPasswordView from './v-reset-password'
import Profile from './v-profile'

import '../assets/style/v-layout.sass';
/*
    The component responsible for displaying the desired content (Registration view, Authorisation view, Reset password view or Profile menu)
*/
type IProps = IViewProps;

const LayoutView: React.FC<IProps> = ({
  className,
  children,
  onAuthComplete,
  height
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isModalProfile, setModalProfile] = useState<boolean>(false);
  const isAuth = localStorage.getItem('token')

  useEffect(() => {
    if (!isAuth)
      return
  }, [])

  const modalContent = () => {
        return <Profile setModalProfile={setModalProfile} height={height}/>
  }

  return (
    <div className={cn(className, 'v-layout')}>
      <HeaderView
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onAuthComplete={onAuthComplete}
        isModalProfile={isModalProfile}
        setModalProfile={setModalProfile}
        renderModalContent={() => (
          modalContent()
        )}
        height={height}
      />
      <div className="v-layout__content">{children}</div>
    </div>
  );
};

export default LayoutView;
