import React from 'react';
import moment from 'moment';
import Text from '../components/typography/Text';
import classnames from 'classnames';
import lockIcon from '../assets/img/blocked.svg';
import ProfitPercentView from '../components/profit-percent/v-profit-percent';
import Tooltip from '../components/tooltip';
import { PredictionScreenerEnum, tooltipTexts } from '../api/enums';

// 0-40 == -100 0
// 50 - 0
// 60-100 = 0 100

function calcPercent(prediction: any) {
  if (prediction < 0) {
    return ((prediction + 100) / 100) * 40;
  }
  if (prediction > 0) {
    return (prediction / 100) * 40 + 60;
  }
  return 50;
}

const PredictionDaily: React.FC<{
  prediction?: number;
  predictionRate1Day?: number;
  initialPredictionPrice?: number;
  currentPrice?: number;
}> = ({
  prediction = null,
  predictionRate1Day = 0,
  initialPredictionPrice = 0,
  currentPrice = 0,
}) => {
  return (
    <div className="mb-40">
      <div className="v-currency-overview__subtitle mb-40">
        Daily Prediction ({moment(new Date()).format('DD MMM')}.)
      </div>
      <div className="v-daily-prediction__prediction">
        {prediction === -1000 && (
          <div
            style={{
              height: '30px',
              width: '100%',
              backgroundColor: '#ECF1FF',
              position: 'absolute',
              transform: 'translateY(-100%)',
              justifyContent: 'center',
              fontWeight: 500,
            }}
            className={'flex items-center '}
          >
            <Text>Prediction Locked</Text>
            <img className="ml-8" src={lockIcon} />
          </div>
        )}
        {prediction !== -1000 && (
          <div
            style={{
              position: 'absolute',
              left: `${calcPercent(prediction)}%`,
              transform: 'translateY(-100%) translateX(-50%)',
            }}
            className={'flex flex-col items-center'}
          >
            <Text
              className={classnames('v-daily-prediction__prediction__percent', {
                updating: typeof prediction !== 'number',
              })}
            >
              {` ${
                typeof prediction === 'number'
                  ? Math.abs(prediction)
                  : 'Prediction Updating'
              }`}
              {typeof prediction === 'number' ? '%' : ''}{' '}
            </Text>
            {typeof prediction === 'number' && (
              <div className="v-daily-prediction__prediction__triangle" />
            )}
          </div>
        )}

        <div className="v-daily-prediction__prediction__item"></div>
        <div className="v-daily-prediction__prediction__item"></div>
        <div className="v-daily-prediction__prediction__item"></div>
        <div className="v-daily-prediction__prediction__item"></div>
        <div className="v-daily-prediction__prediction__item"></div>

        <div>Strong sell</div>
        <div>Sell</div>
        <div>Neutral</div>
        <div>Buy</div>
        <div>Strong Buy</div>
      </div>
      <div className="v-daily-prediction__pair">
        <div className="v-daily-prediction__pair__item">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Prediction Rate{' '}
            <Tooltip style={{ marginLeft: '8px' }}>
              {tooltipTexts[PredictionScreenerEnum.dailyPredictionRate]
                ?.text && (
                <div>
                  {
                    tooltipTexts[PredictionScreenerEnum.dailyPredictionRate]
                      ?.text
                  }
                </div>
              )}{' '}
              {tooltipTexts[PredictionScreenerEnum.dailyPredictionRate]
                ?.desc && (
                <div style={{ marginTop: '24px', fontStyle: 'italic' }}>
                  {
                    tooltipTexts[PredictionScreenerEnum.dailyPredictionRate]
                      ?.desc
                  }
                </div>
              )}{' '}
            </Tooltip>
          </div>
          <div>
            {typeof predictionRate1Day === 'number' &&
            typeof prediction === 'number'
              ? `${predictionRate1Day}%`
              : 'Rate updating'}
          </div>
        </div>
        <div className="v-daily-prediction__pair__item">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Initial prediction price{' '}
            <Tooltip style={{ marginLeft: '8px' }}>
              {tooltipTexts['initialPrice']?.text && (
                <div>{tooltipTexts['initialPrice']?.text}</div>
              )}{' '}
              {tooltipTexts['initialPrice']?.desc && (
                <div style={{ marginTop: '24px', fontStyle: 'italic' }}>
                  {tooltipTexts['initialPrice']?.desc}
                </div>
              )}{' '}
            </Tooltip>
          </div>
          <div>
            {(typeof initialPredictionPrice === 'number' ||
              typeof initialPredictionPrice === 'string') &&
            typeof prediction === 'number'
              ? `${initialPredictionPrice} BTC`
              : 'Price Updating'}
          </div>
        </div>
        {prediction !== -1000 && (
          <>
            <div></div>
            <div className="v-daily-prediction__pair__item">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Initial price vs current{' '}
                <Tooltip style={{ marginLeft: '8px' }}>
                  {tooltipTexts['initialVSCurrentPrice']?.text && (
                    <div>{tooltipTexts['initialVSCurrentPrice']?.text}</div>
                  )}{' '}
                  {tooltipTexts['initialVSCurrentPrice']?.desc && (
                    <div style={{ marginTop: '24px', fontStyle: 'italic' }}>
                      {tooltipTexts['initialVSCurrentPrice']?.desc}
                    </div>
                  )}{' '}
                </Tooltip>
              </div>
              <div>
                {(typeof initialPredictionPrice === 'number' ||
                  typeof initialPredictionPrice === 'string') &&
                typeof prediction === 'number' ? (
                  <ProfitPercentView
                    percent={
                      ((prediction < 0 ? 1 : -1) *
                        Math.round(
                          (currentPrice / initialPredictionPrice - 1) * 10000,
                        )) /
                      100
                    }
                    // reversed={currentPrice - initialPredictionPrice > 0}
                    style={{
                      height: '25px',
                      paddingLeft: '8px',
                      paddingRight: '8px',
                    }}
                  />
                ) : (
                  'Updating'
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PredictionDaily;
