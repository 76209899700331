import PropTypes from 'prop-types';
import "./style.scss";

import cn from "classnames";
import * as React from "react";

const ButtonLoader: React.FC<{
    disabled: boolean;
    opacity: string;
    size: string;
    onClick: () => void;
    type: "button" | "submit" | "reset" | undefined;
    isLoad: boolean;
    children: any
}> = ({
    disabled = false,
    opacity,
    size,
    onClick,
    type = "button",
    isLoad = false,
    children
}) => {
        const colors = {
            primary: 'button--primary',
            secondary: 'button--secondary',
            link: 'button--link',
            icon: 'button--icon',
            light: 'button--light',
            frame: 'button--frame',
            danger: 'button--danger',
            roundPrimary: 'round--primary',
            clear: 'button--clear'
        }

        const sizes = {
            large: 'button--large',
            small: 'button--small',
            little: 'button--little'
        }

        return (
            <button
                style={{ opacity: `${opacity}` }}
                disabled={disabled}
                type={type}
                onClick={type !== 'submit' ? () => onClick() : undefined}
                data-test="testButton"
                className={cn(
                    sizes[size],
                    'button',
                )}
            >
                {isLoad ? (
                    <div className="wrapBtnPreloader">
                        <div className="blockBtnPreloader">
                            <div className="sk-three-bounce">
                                <div className="sk-bounce-1 sk-child" />
                                <div className="sk-bounce-2 sk-child" />
                                <div className="sk-bounce-3 sk-child" />
                            </div>
                        </div>
                    </div>
                ) : (
                    children
                )}
            </button>
        );
    };

export default ButtonLoader;

// Button.propTypes = {
//     disabled: PropTypes.bool,
//     size: PropTypes.oneOf(['large', 'small']),
//     color: PropTypes.oneOf(['primary', 'link', 'secondary', 'icon', 'light', 'frame']),
//     children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
//     onClick: PropTypes.func,
// };

