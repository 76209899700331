import cn from 'classnames';
import React from 'react';
import axios from 'axios';
import { Moment } from 'moment'
import { loadStripe } from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';

import close from '../assets/img/close-modal.svg'
import MasterCard from '../assets/img/master-card.svg'
import Visa from '../assets/img/visa.svg'
import DefaultCard from '../assets/img/default-card.svg'
import trash from '../assets/img/trash.svg'
import { logout, getProfileInfo } from '../api/api';
import '../assets/style/v-profile.sass';
import '../assets/style/v-header.sass';

import ButtonLoader from '../components/button';
import Modal from '../components/modal';

let moment = require('moment');
let stripe;
const PUBLIC_KEY = process.env.REACT_APP_STRIPE_PK || ''
const STRIPE_SERVER_URL = process.env.REACT_APP_API || ''

interface IReg {
    email: string;
    free_license: boolean;
    date: Moment;
}

interface ICard {
    last4num: string,
    bank: string
}

const LayoutView: React.FC<{
    setModalProfile: (value: boolean) => void;
    height?: number 
}> = ({
    setModalProfile,
    height
}) => {
        const [values, setValues] = React.useState<IReg>({ email: '', free_license: true, date: moment() });
        const [message, setMessage] = React.useState<{ text: string }>({ text: '' });
        const [cardInfo, setCardInfo] = React.useState<ICard>({ last4num: '', bank: '' });
        const [loading, setLoading] = React.useState<boolean>(false)
        const [loadingDelete, setLoadingDelete] = React.useState<boolean>(false)
        const [isModalDelete, setIsModalDelete] = React.useState<boolean>(false)
        const [cardLoading, setCardLoading] = React.useState<boolean>(false)

        React.useEffect(() => {
            if (!localStorage.getItem('token'))
                signOut()
            else
                axios.defaults.headers.common["Authorization"] = localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : ''

            setCardLoadingAsync(true)
            getProfileInfo().then(async (data) => {
                if (data) {
                    setValues(data)
                    try {
                        await axios.get(`${STRIPE_SERVER_URL}/payments/subscription`).then(async (response) => {
                            await checkSession().then(() => setCardLoadingAsync(false))
                        })
                    } catch (e) {
                        Sentry.captureException(e);
                        setMessage({ text: e.response?.data?.message || e.response.data.status });
                    }
                    finally {
                        setCardLoadingAsync(false)
                    }
                    getStripeAcc()
                }
                else {
                    setMessage({ text: "Couldn't get the data. Try again later." });
                    setCardLoadingAsync(false)
                }
            })
        }, [])

        const setCardLoadingAsync = async (loading) => {
            await setCardLoading(loading)
        }

        const signOut = async () => {
            await logout()
            setModalProfile(false);
        }

        const getCardInfo = async () => {
            try {
                await axios.get(`${STRIPE_SERVER_URL}/payments/card`).then(async (response) => {
                    setCardInfo({ last4num: response.data.card.last4, bank: response.data.card.brand })
                })
            } catch (e) {
                Sentry.captureException(e);
                setMessage({ text: e.response?.data?.message || e.response.data.status });
            }
        }

        const getStripeAcc = async () => {
            stripe = await loadStripe(PUBLIC_KEY);
        }

        const checkSession = async () => {
            await axios.get(`${STRIPE_SERVER_URL}/payments/checkoutSession`)
                .then(async (data) => {
                    if (data.data.payment_status == 'paid' || data.data.payment_status == 'no_payment_required')
                        await getCardInfo()
                })
                .catch((e) => {
                    Sentry.captureException(e);
                    setMessage({ text: e.response?.data?.message || e.response.data.status });
                });
        }

        const createCheckoutSession = () => {
            return axios.post(`${STRIPE_SERVER_URL}/payments/checkoutSession/create`)
                .catch((e) => {
                    Sentry.captureException(e);
                    setMessage({ text: e.response?.data?.message || e.response.data.status });
                })
        };

        const deleteCard = async () => {
            try {
                setCardLoadingAsync(true)
                await axios.delete(`${STRIPE_SERVER_URL}/payments/card/delete`).then(async (data) => {
                    setCardInfo({ last4num: '', bank: '' })
                    // if (data.data.code !== 200)// Смена поведения при удалении,т.е получение парты только при отсутствии 200 ответа, а при 200 выводим уведомление, анологичное страйповскому (чтобы при повторном открытии профиля выглядело одинаково)
                        getCardInfo();
                    // else
                    //     setMessage({text: 'The user does not have a card'})
                })
            } catch (e) {
                Sentry.captureException(e);
                if (e.response.data.code == 400 || e.response.data.code == 404)
                    setCardInfo({ last4num: '', bank: '' })
                setMessage({ text: e.response?.data?.message || e.response.data.status });
            }
            finally {
                setCardLoadingAsync(false)
            }
        };

        return (
            <>
                <div className="flex flex-col v-profile" style={{marginTop:`${height}px`, height: `calc(100% - ${height}px)`}}>
                    <div className='v-profile__content'>
                        <div className='v-profile__title v-profile__spase-between'>
                            Account
                            <img
                                height='44'
                                src={close}
                                onClick={() => setModalProfile(false)}
                            />
                        </div>
                        <label className='v-profile__text'>Hello,&nbsp;<span>{values.email}</span></label>

                        {message.text && <div className={'v-profile__notification v-profile__danger'}>
                            {message.text}
                            <img
                                className='pointer'
                                height='20'
                                src={close}
                                onClick={() => {
                                    setMessage({ text: '' })
                                }}
                            />
                        </div>}

                        <h4 className='mt-50'>Subscription information</h4>
                        <div className='v-profile__spase-between mt-20 v-profile__item'>
                            <label className='bold'>Membership type</label>
                            <label>{values.free_license ? 'Basic' : 'Full access'}</label>
                        </div>
                        {!values.free_license &&
                            <div className='v-profile__spase-between mt-20 v-profile__item'>
                                <label className='bold'>Next payment</label>
                                <label>{moment(values.date).format("DD.MM.YYYY")}&nbsp;<span>({Math.round((moment(values.date) - moment()) / 86400000)}&nbsp;{Math.round((moment(values.date) - moment()) / 86400000) > 1 ? 'days' : 'day'}&nbsp;left)</span></label>
                            </div>
                        }
                        {cardLoading ? (
                            <div className='flex items-center flex-col mt-30'>
                                <div className="loader" />
                                Load information
                            </div>)
                            :
                            (cardInfo.last4num ?
                                <>
                                    <h4 className='mt-50'>Payment info</h4>
                                    <div className='mt-20 v-profile__item v-profile__card-info'>
                                        <img src={cardInfo.bank === 'visa' ? Visa : cardInfo.bank === 'mastercard' ? MasterCard : DefaultCard} />
                                        <label><span className='capitalize'>{cardInfo.bank}</span> ending in {cardInfo.last4num}</label>
                                        <button
                                            disabled={loading}
                                            onClick={() => {
                                                setLoading(true)
                                                setIsModalDelete(true)
                                                setLoading(false)
                                            }}>
                                            <img src={trash} />
                                        </button>
                                    </div>
                                </>
                                :
                                <div className='v-profile__add-card'>
                                    <label>Unlock all 140+ Daily Prediction with Cryptoforecast membership</label>
                                    <h3>$60&nbsp;/month</h3>
                                    <ButtonLoader
                                        disabled={loading}
                                        opacity={!loading ? '1' : '0.4'}
                                        size={'large'}
                                        onClick={async () => {
                                            setLoading(true)
                                            try {
                                                await createCheckoutSession().then((data) => {
                                                    if (data)
                                                        stripe?.redirectToCheckout({
                                                            sessionId: data.data.id
                                                        });
                                                })
                                            }
                                            catch (e) {
                                                console.log(e)
                                                setMessage({ text: e.response?.data?.message || e.response.data.status });
                                            }
                                            setLoading(false)
                                        }}
                                        type="button"
                                        isLoad={loading}
                                        children="Buy now" />
                                </div>
                            )}
                    </div>
                    <a href='#' className='v-profile__logout'
                        onClick={() => signOut()}>Log out</a>
                </div>
                <Modal
                    onRequestClose={() => setIsModalDelete(false)}
                    isOpen={isModalDelete}
                    contentClassName="modal-content--centered"
                >
                    <div className="v-header__modal-content v-header__modal-p v-profile__modal-delete">
                        <div className="v-profile__modal-delete__main-content">
                            <h3>You are about to cancel your membership, are you sure?</h3>
                            <label>Once the card is deleted, Stripe will stop charging for the subscription. When you cancel a subscription you’ll still be able to use your subscription for the time you’ve already paid.</label>
                            <div className="v-profile__modal-delete__btn-group">
                                <button
                                    disabled={loadingDelete}
                                    style={{ marginRight: '15px', background: 'rgba(243, 187, 187, 0.49)' }}
                                    onClick={async () => {
                                        setLoadingDelete(true)
                                        try {
                                            await deleteCard()
                                            setIsModalDelete(false)
                                        } catch (e) {
                                            console.log(e)
                                        }
                                        setLoadingDelete(false)
                                    }}>Yes</button>
                                <button
                                    onClick={() => {
                                        setIsModalDelete(false)
                                    }}>No</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    };

export default LayoutView;
