import cn from 'classnames';
import React from 'react';
import * as yup from 'yup';

import '../assets/style/v-auth.sass';

import { resetPassword } from '../api/api';
import logo from '../assets/img/logo-registration.svg'
import back from '../assets/img/arrow-back.svg'
import ButtonLoader from '../components/button';
import { useHistory } from 'react-router-dom';
/*
    Content of the reset password window with yup verification
*/
interface IReg {
    email: string;
}
const LayoutView: React.FC<{
}> = ({
}) => {
        const [values, setValues] = React.useState<IReg>({ email: "" });
        const [message, setMessage] = React.useState<{ email: string, error: string }>({ email: "", error: "" });
        const [sentEmail, setSentEmail] = React.useState<boolean>(false);
        const [loading, setLoading] = React.useState<boolean>(false)
        let history = useHistory()
        let validEmail = yup.object().shape({
            email: yup.string().email().min(5),
        });

        const error = () => {
            setMessage({ email: "", error: "" })
            if (values.email !== "" && !validEmail.isValidSync(values)) {
                setMessage({ ...message, email: "Incorrect email" })
            }
        }

        React.useEffect(() => {
            error()
        }, [values]);

        return (
            <>
                <div className="flex items-center h-full flex-col v-modal-auth">
                    <div className="flex pointer v-modal-auth__logo" onClick={() => history.push('/')}>
                        <img
                            src={logo}
                        />
                    </div>
                    <div className="flex items-center h-full flex-col justify-center">
                        <h4 className="v-reset-password__h4 mb-40">{sentEmail ? `Sent! Check your inbox and spam folder at ${values.email} for instructions on resetting your password.` : "Send password reset instructions"}</h4>
                        <input
                            style={sentEmail ? { display: 'none' } : {}}
                            type='email'
                            placeholder='Enter email'
                            className={validEmail.isValidSync(values) ? 'input-text' : 'input-text input-text__invalid'}
                            value={values.email}
                            onChange={(e) => {
                                setMessage({ ...message, email: "", error: '' })
                                setValues({ ...values, email: e.target.value });
                            }}
                        />
                        {message.email &&
                            <div className='v-modal-auth__error-message'>{message.email}</div>}
                        <div style={sentEmail ? { display: 'none' } : { width: '100%' }}>
                            <ButtonLoader
                                disabled={loading}
                                opacity={values.email != "" && !loading ? '1' : '0.4'}
                                size={'large'}
                                onClick={async () => {
                                    setLoading(true)
                                    error()
                                    if (validEmail.isValidSync(values)) {
                                        const response = await resetPassword(values.email)
                                        if (response === true) {
                                            setSentEmail(true)
                                        }
                                        else
                                            setMessage({ ...message, error: response });
                                    }
                                    setLoading(false)
                                }}
                                type="button"
                                isLoad={loading}
                                children="Send email" />
                        </div>
                        {message.error &&
                            <div className='v-modal-auth__error-message'>{message.error}</div>}
                        <div className='line' />
                        <div>
                            <a href="#" onClick={() => history.push('/auth')} className='mr-25'>Log in</a>
                            <a href="#" onClick={() => history.push('/registration')}>Sign up for an account</a>
                        </div>
                    </div>
                </div>
            </>
        );
    };

export default LayoutView;
