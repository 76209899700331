import * as React from 'react';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

interface IParagraphProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {}

const Paragraph: React.FC<IParagraphProps> = ({ children, ...rest }) => {
  return <p {...rest}>{children}</p>;
};

export default Paragraph;
