import { RouteComponentProps } from "react-router-dom";
import * as qs from 'qs';
import moment from "moment";

export function calculatePredictionScreenerProfit(ticker: string, response: Array<{
  timestamp: number;
  data: {
    tr: {
      TR_TOT_AVG_PR_BTC: number;
    };
    pr: {
      predict_1_day_lgb: number;
      predict_30_day_lgb: number;
    };
  };
}>) {
  const InitialState = {
    predictionRate1Day: 0,
    count: 0,
    profit30d: 0,
    profit1d: 0,
    currency: ticker,
    predictionProfits: [] as Array<{ timestamp: number, profit: number }>,
  };
  if (ticker === "BTC") {
    return InitialState;
  }

  return response.reduce((memo, item, index, array) => {
    if (index <= 30) {
      return memo;
    }
    const predictionToday1Day = array[index - 1].data.pr.predict_1_day_lgb;
    const priceYesterday = array[index - 1].data.tr.TR_TOT_AVG_PR_BTC;
    const priceToday = item.data.tr.TR_TOT_AVG_PR_BTC;
    const priceDiff = priceToday - priceYesterday;

    const predictionRate1Day = (priceDiff < 0 && predictionToday1Day < 0.5) ||
      (priceDiff >= 0 && predictionToday1Day >= 0.5);

    return {
      ...memo,
      predictionRate1Day: Number(predictionRate1Day) + memo.predictionRate1Day,
      count: memo.count + 1,
      profit30d: memo.profit30d + ((Math.abs(priceDiff) * 100) / priceToday) * (predictionRate1Day ? 1 : -1),
      profit1d: ((Math.abs(priceDiff) * 100) / priceToday) * (predictionRate1Day ? 1 : -1),
      predictionProfits: [...memo.predictionProfits, {
        timestamp: item.timestamp,
        profit: ((Math.abs(priceDiff) * 100) / priceToday) * (predictionRate1Day ? 1 : -1),
        priceYesterday,
        priceToday,
        accuracy: predictionRate1Day,
        predictionStrength24h: Math.round((predictionToday1Day * 2 - 1) * 100),
        rate: array.slice(index - 31, index).reduce((currentRate, item, index, array) => {
          if (index === 0) {
            return currentRate
          }
          const predictionToday1Day = array[index - 1].data.pr.predict_1_day_lgb;
          const priceYesterday = array[index - 1].data.tr.TR_TOT_AVG_PR_BTC;
          const priceToday = item.data.tr.TR_TOT_AVG_PR_BTC;
          const priceDiff = priceToday - priceYesterday;

          const predictionRate1Day = (priceDiff < 0 && predictionToday1Day < 0.5) ||
            (priceDiff >= 0 && predictionToday1Day >= 0.5);

          return currentRate + Number(predictionRate1Day)
        }, 0)
      }],
    };
  }, InitialState);
}
export const updateSearch = (
  value: { [key: string]: any },
  history: RouteComponentProps['history'],
  search = ''
) =>
  history.push({
    search: qs.stringify({
      ...qs.parse(search, {
        ignoreQueryPrefix: true
      }),
      ...value
    })
  })


export const formateTimestamp = (date: {[key: string]: any, timestamp: number | string}[]) => (format: string) => date.map((item) => ({
    ...item,
    timestamp: moment(new Date(item.timestamp)).format(format)}))