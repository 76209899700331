import classnames from 'classnames';
import clockIcon from '../../assets/img/clock.svg';
import lockIcon from '../../assets/img/blocked.svg';
import * as React from 'react';

interface IDifferenceStrength {
  type: 'bullish' | 'bearish' | 'neutral' | 'pending' | 'locked' | 'locked_field';
  percent?: number;
}

const TypeToText: { [key in IDifferenceStrength['type']]: string } = {
  bearish: 'Bearish Strength',
  bullish: 'Bullish Strength',
  neutral: 'Neutral Trend',
  pending: 'Prediction updating',
  locked: 'Prediction Locked',
  locked_field: 'Locked',
};

const DifferenceStrength: React.FC<IDifferenceStrength> = ({
  type,
  percent = 0,
}) => {
  return (
    <div className={classnames('difference-strength', type)}>
      <div
        className={classnames('flex items-center', {
          'w-full justify-between': type === 'pending' || type === 'locked' || type === 'locked_field',
        })}
      >
        {TypeToText[type]}{' '}
        {type !== 'neutral' && type !== 'pending' && type !== 'locked' && type !== 'locked_field'
          ? `${Math.abs(Math.min(100, percent))}%`
          : null}
        {type === 'pending' && <img className="mr-10" src={clockIcon} />}
        {(type === 'locked' || type === 'locked_field') && <img className="mr-10" src={lockIcon} />}
      </div>
      <div
        className="bg"
        style={{
          right:
            type === 'neutral' || type === 'pending' || type === 'locked' || type === 'locked_field'
              ? 0
              : `${100 - Math.min(100, percent)}%`,
        }}
      ></div>
    </div>
  );
};

export default DifferenceStrength;
