import React, { useState, useEffect } from 'react';
import moment from 'moment';

function getLimitDate() {
  const startTime = moment()
    .utc()
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);

  const endTime = moment()
    .utc()
    .set('hour', 1)
    .set('minute', 30)
    .set('second', 0)
    .set('millisecond', 0);

  const currentTime = moment().utc();

  if (currentTime.isAfter(startTime) && currentTime.isBefore(endTime)) {
    endTime.subtract({
      hour: currentTime.hour(),
      minute: currentTime.minute(),
      second: currentTime.second(),
    });

    return endTime;
  }

  return undefined;
}

const PredictionTimer: React.FC<{ className?: string }> = ({ className }) => {
  const [currentTime, setCurrenTime] = useState(getLimitDate());

  useEffect(() => {
    const timerId = setTimeout(() => {
      setCurrenTime(getLimitDate());
      console.log(getLimitDate()?.format('HH:mm:ss'));
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [currentTime]);

  if (!currentTime) {
    return null;
  }

  return (
    <div style={{ fontSize: 18 }} className={`flex items-center ${className}`}>
      Next prediction in{' '}
      <span style={{ color: '#5079EA' }} className="font-bold ml-4">
        {`${currentTime.hour()}h ${currentTime.minute()}m ${currentTime.second()}s`}
      </span>
    </div>
  );
};

export default PredictionTimer;
