import "./style.scss";

import cn from "classnames";
import * as React from "react";

const Checkbox: React.FC<React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>> = ({ children, className, ...rest }) => {
  return (
    <label
      className={cn("checkbox", className, {
        "checkbox--content": Boolean(children),
      })}
    >
      <input
        {...rest}
        type="checkbox"
        className={cn("visually-hidden checkbox__input")}
      />
      <svg className="checkbox__box" viewBox="0 0 12 12">
        <rect className="checkbox__border" width="12" height="12" rx="2" />
        <rect
          className="checkbox__border-inner"
          x="1"
          y="1"
          width="10"
          height="10"
          rx="1"
        />
        <rect className="checkbox__square" x="2" y="2" width="8" height="8" />
      </svg>
      <span className="checkbox__text">{children}</span>
    </label>
  );
};

export default Checkbox;
