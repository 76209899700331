import * as React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import cn from "classnames";

const CoinMenuView: React.FC = () => {
  const { currency } = useParams();
  const history = useHistory();

  return (
    <div className="v-coin-menu mb-40">
      <Link to={"/"} className='v-coin-menu__item relative'>
        <span className='v-coin-menu__triangle'/>Back
      </Link>
      <Link to={`/coin/${currency}`} className={cn("v-coin-menu__item", {active: history.location.pathname === `/coin/${currency}`})}>
        Overview
      </Link>
      <Link to={`/coin/${currency}/history`} className={cn("v-coin-menu__item", {active: history.location.pathname === `/coin/${currency}/history`})}>
        Prediction history
      </Link>
    </div>
  );
};

export default CoinMenuView;
