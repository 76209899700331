import * as React from 'react';
import Title from './typography/Title';
import Paragraph from './typography/Paragraph';
import ProfitPercentView from './profit-percent/v-profit-percent';

const TotalProfitView: React.FC<{ profit?: number; per: string }> = ({
  profit = 0,
  per = '0',
}) => {
  return (
    <div className="v-total-profit">
      <Title className="v-total-profit__title">
        Total <br /> Profit <br />
        <span>
          in{' '}
          <img
            className="ml-6 mr-6"
            width={20}
            height={20}
            src="/img/coins/bitcoin.png"
          />{' '}
          BTC
        </span>
      </Title>
      <div className='v-total-profit__profit'>
        <ProfitPercentView percent={+profit.toFixed(2)} />
        <Paragraph className="v-total-profit__description">per {per}</Paragraph>
      </div>
    </div>
  );
};

export default TotalProfitView;
