import * as React from 'react';
import Title from './typography/Title';
import Text from './typography/Text';
import ProfitPercentView from './profit-percent/v-profit-percent';

const CoinHeaderView: React.FC<{
  title?: string;
  currency?: string;
  priceUSD?: number;
  priceBTC?: number;
  changeUSD?: number;
  changeBTC?: number;
}> = ({
  children,
  changeUSD = 0,
  changeBTC = 0,
  currency,
  priceUSD,
  priceBTC,
  title,
}) => {
  return (
    <>
      <Title level={2} className="v-coin-header__header">
        {title} <Text>{currency}</Text>
      </Title>
      <div className="v-coin-header__currency">
        <div className="v-coin-header__currency-row mb-4">
          <Text className="min-w-140 mr-4">${priceUSD}</Text>
          <ProfitPercentView percent={changeUSD} />
        </div>
        <div className="v-coin-header__currency-row">
          <Text className="min-w-140 mr-4">{`${priceBTC} BTC`}</Text>
          <ProfitPercentView percent={changeBTC} />
        </div>
      </div>
    </>
  );
};

export default CoinHeaderView;
