/* eslint-disable @typescript-eslint/camelcase */
import cn from 'classnames';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import LayoutView from './v-layout';
import PredictionView from './vc-prediction';
import CoinMenuView from '../components/coins-menu/v-coin-menu';
import CoinHeaderView from '../components/v-coin-header';
import { getCurrencyInfo, getPredictionHistory } from '../api/api';
import Modal from '../components/modal';
import SubmitButton from '../components/submit-button';
import exitIcon from '../assets/img/exit.svg';
import Title from '../components/typography/Title';
import Text from '../components/typography/Text';
import Collapse from '../components/collapse';
import settingsIcon from '../assets/img/settings.svg';
import questionIcon from '../assets/img/question.svg';
import InputMask from 'react-input-mask';
import { pickBy, isEmpty } from 'lodash';
import moment from 'moment';
import Sticky from '../components/sticky';

export interface ISort {
  sortBy?: string;
  orderBy?: string;
}

const HistoryView: React.FC = () => {
  const { currency } = useParams();
  const [currencyInfo, setCurrencyInfo] = React.useState<any>();
  const [sort, setSort] = React.useState<ISort>({});
  const [dailyPredictions, setDailyPredictions] = React.useState<any[]>();
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [historyFilters, setHistoryFilters] = React.useState<{
    coinHistoryTimeframes: {
      start_date?: string;
      end_date?: string;
    };
  }>({
    coinHistoryTimeframes: {
      start_date: undefined,
      end_date: undefined,
    },
  });

  const [modalState, setModalState] = React.useState(historyFilters);

  const predictionHistoryFilters = React.useMemo(() => {
    const { start_date, end_date } = historyFilters.coinHistoryTimeframes;
    const dateToTimestamp = (date: string) =>
      moment(moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')).format('x');

    return pickBy({
      sort_by: sort.sortBy,
      sort_type: sort.orderBy,
      start_date: start_date ? dateToTimestamp(start_date) : undefined,
      end_date: end_date ? dateToTimestamp(end_date) : undefined,
    });
  }, [historyFilters, sort.sortBy, sort.orderBy]);

  React.useEffect(() => {
    getPredictionHistory(currency!, predictionHistoryFilters).then((data) => {
      setDailyPredictions(data);
      if (
        !historyFilters.coinHistoryTimeframes.start_date &&
        !historyFilters.coinHistoryTimeframes.end_date &&
        !isEmpty(data)
      ) {
        setHistoryFilters((state) => ({
          ...state,
          coinHistoryTimeframes: {
            ...historyFilters.coinHistoryTimeframes,
            start_date: moment(data[data.length - 1].timestamp).format(
              'DD-MM-YYYY',
            ),
            end_date: moment(data[0].timestamp).format('DD-MM-YYYY'),
          },
        }));
      }
    });
  }, [predictionHistoryFilters]);

  React.useEffect(() => {
    getCurrencyInfo(currency!).then(setCurrencyInfo);
    const intervalId = setInterval(() => {
      getCurrencyInfo(currency!).then(setCurrencyInfo);
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  React.useEffect(() => {
    setModalState(historyFilters);
  }, [historyFilters]);

  const { coinHistoryTimeframes } = modalState;

  const [isSticky, setSticky] = React.useState(false);
  const [isBaner, setBaner] = React.useState(true);
  const ref = React.useRef<HTMLDivElement>(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref?.current?.getBoundingClientRect()?.top <= 0);
    }
  };
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <div className="v-app">
      {isBaner &&
        <div className={`sticky-wrapper${isSticky ? ' sticky' : ''}`} ref={ref}>
          <Sticky setClose={() => setBaner(false)} />
        </div>
      }
      <Modal
        onRequestClose={() => {
          setIsModalVisible(false);
        }}
        isOpen={isModalVisible}
      >
        <SubmitButton
          header={() => (
            <div className="v-currency-overview__text-wrapper">
              <img

                className="ml-14 pointer  v-currency-overview__close-icon"
                src={exitIcon}
                onClick={() => setIsModalVisible(false)}
              />
              <Title
                level={1}
                className="v-currency-overview__modal-title ml-20"
              >
                Filters
              </Title>
            </div>
          )}
          render={() => (
            <Collapse
              initialOpened={true}
              Header={() => <Title level={2}>Standart metrics</Title>}
            >
              <div className="metrics-field">
                <div className="metrics-field__type">
                  <div className="metrics-field__type-name">
                    <img src={questionIcon} />
                    <span className="ml-10">Prediction period</span>
                  </div>
                </div>
                <div className="metrics-field__filters">
                  <InputMask
                    value={coinHistoryTimeframes.start_date}
                    placeholder="DD-MM-YYYY"
                    className="input w-110"
                    mask="99-99-9999"
                    onChange={(e) => {
                      e.persist();
                      setModalState((state) => ({
                        ...state,
                        coinHistoryTimeframes: {
                          ...coinHistoryTimeframes,
                          start_date: e.target.value,
                        },
                      }));
                    }}
                  />
                  <Text className="px-4">to</Text>
                  <InputMask
                    value={coinHistoryTimeframes.end_date}
                    placeholder="DD-MM-YYYY"
                    className="input w-110"
                    mask="99-99-9999"
                    onChange={(e) => {
                      e.persist();
                      setModalState((state) => ({
                        ...state,
                        coinHistoryTimeframes: {
                          ...coinHistoryTimeframes,
                          end_date: e.target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Collapse>
          )}
          onSubmit={() => {
            setHistoryFilters(modalState);
            setIsModalVisible(false);
          }}
        >
          Apply
        </SubmitButton>
      </Modal>
      <LayoutView className={cn('v-app__layout')} height={isBaner && ref.current?.clientHeight || 0}>
        <CoinMenuView />
        {currencyInfo && (
          <CoinHeaderView
            {...{ setIsModalVisible }}
            title={currencyInfo.title}
            currency={currencyInfo.ticker}
            priceUSD={currencyInfo.priceUSD}
            priceBTC={currencyInfo.priceBTC}
            changeUSD={currencyInfo.dailyChangeUSD}
            changeBTC={currencyInfo.dailyChangeBTC}
          />
        )}
        <div className="v-landing__title-wrapper mb-30">
          <Title level={2}>1 Day Prediction</Title>
          <img
            width={29}
            height={29}
            className="ml-14 pointer"
            src={settingsIcon}
            onClick={() => setIsModalVisible(true)}
          />
        </div>
        {dailyPredictions && (
          <PredictionView {...{ sort, setSort }} data={dailyPredictions} />
        )}
      </LayoutView>
    </div>
  );
};

export default HistoryView;
