import React from 'react';
import questionIcon from '../../assets/img/question.svg';
import Icon from '../../assets/img/arrow-tooltip.svg';

const Tooltip: React.FC<{ style?: React.CSSProperties }> = ({
  children,
  style,
}) => {
  return (
    <div className="ap-tooltip" style={style}>
      <img className="ap-tooltip__icon" src={questionIcon} />

      <div className="ap-tooltip__info">
        {children} <img src={Icon} />{' '}
      </div>
    </div>
  );
};

export default Tooltip;
