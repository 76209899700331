import cn from 'classnames';
import * as React from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import '../assets/style/common.sass';
import '../assets/style/difference-strength.sass';
import '../assets/style/v-prediction-screener.sass';
import '../assets/style/font.sass';
import '../assets/style/v-header.sass';
import '../assets/style/v-app.sass';
import '../assets/style/v-landing.sass';
import '../assets/style/utils.sass';
import '../assets/style/v-table.sass';
import '../assets/style/v-prediction-profit.sass';
import '../assets/style/v-profit-percent.sass';
import '../assets/style/v-total-profit.sass';
import '../assets/style/v-currency-overview.sass';
import '../assets/style/v-daily-prediction.sass';
import '../assets/style/v-coin-menu.sass';
import '../assets/style/v-coin-header.sass';

import Landing from './v-landing';
import CurrencyOverview from './v-currency-overview';
import HistoryView from './v-history';
import NewPasswordView from './v-new-password'
import Auth from './v-auth'
import Registration from './v-registration'
import ForgetРassword from './v-reset-password'

class App extends React.Component<{}, {}> {
  public render() {
    return (
      <Router>
        <Switch>
          <Route path="/auth" exact>
            <Auth />
          </Route>
          <Route path="/coin/:currency" exact>
            <CurrencyOverview />
          </Route>

          <Route path="/coin/:currency/history" exact>
            <HistoryView />
          </Route>

          <Route path="/registration" exact>
            <Registration />
          </Route>

          <Route path="/resetPassword" exact>
            <NewPasswordView />
          </Route>
          
          <Route path="/forgetРassword" exact>
            <ForgetРassword />
          </Route>

          <Route path="/:sortBy?" exact>
            <Landing />
          </Route>
        </Switch>
      </Router>
    );
  }
}

declare let module: object;

export default hot(module)(App);
