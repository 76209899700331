import cn from 'classnames';
import * as React from 'react';
import PredictionDaily from './v-daily-prediction';
import LayoutView from './v-layout';
import PredictionProfit from './v-prediction-profit';
import { useParams } from 'react-router-dom';
import Title from '../components/typography/Title';
import CoinMenuView from '../components/coins-menu/v-coin-menu';
import CoinHeaderView from '../components/v-coin-header';
import TotalProfitView from '../components/v-total-profit';
import { getCurrencyInfo, getCurrencyDailyPredictions } from '../api/api';
import Modal from '../components/modal';
import SubmitButton from '../components/submit-button';
import exitIcon from '../assets/img/exit.svg';
import { PredictionStatisticHeader } from './v-landing';
import { formateTimestamp } from '../api/helpers';
import PredictionTimer from '../components/prediction-timer';
import Sticky from '../components/sticky';

const CurrencyOverview: React.FC = () => {
  const { currency } = useParams<{
    currency: string;
  }>();
  const [currencyInfo, setCurrencyInfo] = React.useState<any>();
  const [dailyPredictions, setDailyPredictions] = React.useState<any[]>();
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);

  const [totalProfitParams, setTotalProfitParams] = React.useState<{
    period: string;
    prediction: string;
    profit: string;
  }>({
    period: 'Days',
    prediction: 'ALL',
    profit: 'ALL',
  });

  React.useEffect(() => {
    getCurrencyInfo(currency).then(setCurrencyInfo);
  }, []);

  React.useEffect(() => {
    getCurrencyDailyPredictions(currency, totalProfitParams)
      .then((res) =>
        formateTimestamp(res)(
          totalProfitParams.period === 'Days' ? 'DD.MM' : 'MM.YY',
        ),
      )
      .then(setDailyPredictions);
  }, [totalProfitParams]);

  const [isSticky, setSticky] = React.useState(false);
  const [isBaner, setBaner] = React.useState(true);
  const ref = React.useRef<HTMLDivElement>(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref?.current?.getBoundingClientRect()?.top <= 0);
    }
  };
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);
  
  return (
    <div className="v-app">
      {isBaner &&
        <div className={`sticky-wrapper${isSticky ? ' sticky' : ''}`} ref={ref}>
          <Sticky setClose={() => setBaner(false)}/>
        </div>
      }
      <Modal
        onRequestClose={() => setIsModalVisible(false)}
        isOpen={isModalVisible}
      >
        <SubmitButton
          onSubmit={() => null}
          header={() => (
            <div className="v-currency-overview__text-wrapper">
              <img
  
                className="ml-14 pointer  v-currency-overview__close-icon"
                src={exitIcon}
                onClick={() => setIsModalVisible(false)}
              />
              <Title
                level={1}
                className="v-currency-overview__modal-title ml-20"
              >
                Filters
              </Title>
            </div>
          )}
          render={() => <></>}
        >
          Apply
        </SubmitButton>
      </Modal>
      <LayoutView className={cn('v-app__layout')} height={isBaner && ref.current?.clientHeight || 0}>
        <CoinMenuView />
        {currencyInfo && (
          <>
            <CoinHeaderView
              title={currencyInfo.title}
              currency={currencyInfo.ticker}
              priceUSD={currencyInfo.priceUSD}
              priceBTC={currencyInfo.priceBTC}
              changeUSD={currencyInfo.dailyChangeUSD}
              changeBTC={currencyInfo.dailyChangeBTC}
            />
            {currencyInfo.dailyPredictionStrength === null && (
              <PredictionTimer className="mt-14 mb-30" />
            )}
            <PredictionDaily
              prediction={currencyInfo.dailyPredictionStrength}
              predictionRate1Day={currencyInfo.dailyPredictionRate}
              initialPredictionPrice={currencyInfo.predictionEnd}
              currentPrice={currencyInfo.priceBTC}
            />
          </>
        )}
        <PredictionStatisticHeader
          className="mb-30 no-mb-mobile"
          activePeriod={totalProfitParams.period}
          activePrediction={totalProfitParams.prediction}
          activeProfit={totalProfitParams.profit}
          title="Prediction Profit Statistics"
          predictions={['Weak', 'Normal', 'Strong', 'ALL']}
          periods={['Days', 'Months']}
          profits={['Bearish', 'Bullish', 'ALL']}
          description={`This could be your profit if you would follow our daily ${currencyInfo?.title} predictions`}
          onClickPeriod={(period) =>
            setTotalProfitParams((s) => ({ ...s, period }))
          }
          onClickPrediction={(prediction) =>
            setTotalProfitParams((s) => ({ ...s, prediction }))
          }
          onClickProfit={(profit) =>
            setTotalProfitParams((s) => ({ ...s, profit }))
          }
        />
        {/* <div className="v-currency-overview__description mb-30"></div> */}
        {dailyPredictions && (
          <div
          className='prediction-profit-container'
          >
            <PredictionProfit className="mb-40" data={dailyPredictions} />
            <TotalProfitView
              profit={dailyPredictions.reduce(
                (memo, item) => memo + item.profit,
                0,
              )}
              per={`${dailyPredictions.length
                } ${totalProfitParams.period.toLowerCase()}`}
            />
          </div>
        )}
      </LayoutView>
    </div>
  );
};

export default CurrencyOverview;
