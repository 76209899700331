import * as React from 'react';
import classnames from 'classnames';
import { debounce } from 'lodash';

import './style.scss';

interface ICollapseProps {
  initialOpened?: boolean;
  disabled?: boolean;
  className?: string;
  Header?: React.FC<{ opened: boolean }>;
}

interface ICollapseState {
  opened: boolean;
  height: number | string;
}

class Collapse extends React.Component<ICollapseProps, ICollapseState> {
  inner = React.createRef<HTMLDivElement>();

  constructor(props: ICollapseProps) {
    super(props);
    this.state = {
      opened: props.initialOpened === undefined ? true : props.initialOpened,
      height: 'auto',
    };
  }

  updateHeight = debounce(() => {
    if (this.inner.current) {
      this.setState({ height: this.inner.current.scrollHeight });
    }
  }, 200);

  componentDidMount() {
    window.addEventListener('resize', this.updateHeight);
    this.updateHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  render() {
    const { children, disabled, className, Header } = this.props;
    const { opened, height } = this.state;

    return (
      <div
        className={classnames('collapse', className, {
          open: opened,
        })}
      >
        <div
          className="collapse__header"
          onClick={(e) => {
            if (disabled) {
              return;
            }
            const newState: ICollapseState = { opened: !opened, height };

            if (this.inner.current) {
              newState.height = this.inner.current.scrollHeight;
            }

            this.setState({ opened: !opened });
          }}
        >
          {Header && <Header opened={opened} />}
          <div className="collapse__icon"></div>
        </div>
        <div
          className="collapse__panel"
          ref={this.inner}
          style={{
            height: opened ? height : 0,
            overflow: opened ? 'unset' : 'hidden',
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default Collapse;
