import moment from 'moment';

export enum PredictionHistoryEnum {
  predictionStrength = 'predictionStrength',
  predictionStart = 'predictionStart',
  predictionEnd = 'predictionEnd',
  profit = 'profit',
  predictionRate = 'predictionRate',
  accuracy = 'accuracy',
  timestamp = 'timestamp',
}

export const predictionHistoryTitles = {
  [PredictionHistoryEnum.predictionStrength]: 'Prediction',
  [PredictionHistoryEnum.profit]: 'Profit',
  [PredictionHistoryEnum.predictionRate]: 'Rate',
  [PredictionHistoryEnum.accuracy]: 'Accuracy',
  [PredictionHistoryEnum.timestamp]: 'Data',
};

export enum PredictionScreenerEnum {
  cryptoindexRating = 'cryptoindexRating',
  priceUSD = 'priceUSD',
  priceBTC = 'priceBTC',
  dailyChangeUSD = 'dailyChangeUSD',
  dailyChangeBTC = 'dailyChangeBTC',
  marketCapUSD = 'marketCapUSD',
  marketCapBTC = 'marketCapBTC',
  coinMarketCapRating = 'coinMarketCapRating',
  dailyPredictionStrength = 'dailyPredictionStrength',
  dailyPredictionRate = 'dailyPredictionRate',
  profitMonthsCount = 'profitMonthsCount',
  dailyPredictionProfitUSD = 'dailyPredictionProfitUSD',
  dailyPredictionProfit = 'dailyPredictionProfit',
  monthlyPredictionProfit = 'monthlyPredictionProfit',
  quarterPredictionProfit = 'quarterPredictionProfit',
  halfYearPredictionProfit = 'halfYearPredictionProfit',
  initialPriceVSCurrent = 'initialPriceVSCurrent'
}

export const tooltipTexts: {
  [key: string]: { text: string; desc?: string };
} = {
  [PredictionScreenerEnum.cryptoindexRating]: {
    text:
      'This rating represents the top 100 coins included in the CIX100 index.',
  },

  [PredictionScreenerEnum.priceUSD]: {
    text:
      "This is the asset's recent trading price (averaged across a number of exchanges) in US Dollars.",
  },
  [PredictionScreenerEnum.priceBTC]: {
    text: "This is the asset's price in terms of Bitcoin.",
  },
  [PredictionScreenerEnum.dailyChangeUSD]: {
    text:
      'This is the percentage change in the current marketcap of an asset, vs USD, compared with 24hrs ago.',
  },
  [PredictionScreenerEnum.dailyChangeBTC]: {
    text:
      'This is the percentage change in the BTC-denominated current marketcap of an asset, compared with 24hrs ago.',
  },
  [PredictionScreenerEnum.marketCapUSD]: {
    text:
      'The Market Capitalization of an asset is defined as the supply of all token on a blockchain times the current price.',
  },
  [PredictionScreenerEnum.marketCapBTC]: {
    text:
      'The Market Capitalization of an asset is defined as the supply of all token on a blockchain times the current price.',
  },
  [PredictionScreenerEnum.dailyPredictionRate]: {
    text:
      'This is an accuracy of forecasts per asset. The number of correct forecasts expressed as a percentage for the last 30 days.',
    desc:
      'Formula: the number of correct forecasts divided by the total number of forecasts for the last 30 days.',
  },
  [PredictionScreenerEnum.dailyPredictionStrength]: {
    text:
      'This indicator shows the power and direction of prediction for the next 24 hours.',
    desc:
      'The higher the value, the higher the probability of working out the trade in the right direction. We determine and show long (bullish) and short (bearish) signals. Based on this, you can understand in which direction the asset will go relative to Bitcoin through 1 day.',
  },
  [PredictionScreenerEnum.dailyPredictionProfitUSD]: {
    text:
      'This is an estimated percentage profit for the last 24 hours for a prediction recalculated in term of US Dollars.',
  },
  ['predictionProfitBTC']: {
    text:
      'This is a calculated percentage profit in Bitcoin you would earn if you would follow prediction for an underlying asset.',
  },

  [PredictionScreenerEnum.profitMonthsCount]: {
    text:
      'Number of months in the last year where BTC trading profitability was positive.',
  },
  ['initialPrice']: {
    text: 'This is the price of the asset the prediction is calculated from.',
  },
  [PredictionScreenerEnum.initialPriceVSCurrent]: {
    text:
      'This indicator compares the current price of an asset with an initial prediction price. The indicator is green and positive if the current entry price is better than the system predicted. The indicator is red and negative if the current entry price is worse than the system predicted.',
    desc:
      'important: Based on our own trading strategies, the optimal entry point is in the indicators from -1.5% to + ∞% depending on the volatility of different assets.',
  },
  ['initialVSCurrentPrice']: {
    text:
      'This indicator compares the current price of an asset with an initial prediction price. The indicator is green and positive if the current entry price is better than the system predicted. The indicator is red and negative if the current entry price is worse than the system predicted.',
    desc:
      'important: Based on our own trading strategies, the optimal entry point is in the indicators from -1.5% to + ∞% depending on the volatility of different assets.',
  },
};

const predictionScreenerTitlesConsts = {
  [PredictionScreenerEnum.priceUSD]: 'Price (USD)',
  [PredictionScreenerEnum.priceBTC]: 'Price (BTC)',
  [PredictionScreenerEnum.dailyChangeUSD]: 'Change USD (24h)',
  [PredictionScreenerEnum.dailyChangeBTC]: 'Change BTC (24h)',
  [PredictionScreenerEnum.marketCapUSD]: 'Market Cap (USD)',
  [PredictionScreenerEnum.marketCapBTC]: 'Market Cap (BTC)',
  [PredictionScreenerEnum.dailyPredictionStrength]: () =>
    `Prediction (${moment(new Date()).utc().format('DD MMM')})`,
  [PredictionScreenerEnum.dailyPredictionRate]: 'Prediction Rate (30d)',
  [PredictionScreenerEnum.cryptoindexRating]: 'Cryptoindex Rating',
  [PredictionScreenerEnum.coinMarketCapRating]: 'CMC Rating',
  [PredictionScreenerEnum.profitMonthsCount]: 'Profit Months of 12',
  [PredictionScreenerEnum.dailyPredictionProfitUSD]: 'Prediction Profit (USD)',
  [PredictionScreenerEnum.dailyPredictionProfit]: '24h',
  [PredictionScreenerEnum.monthlyPredictionProfit]: '30d',
  [PredictionScreenerEnum.quarterPredictionProfit]: '3m',
  [PredictionScreenerEnum.halfYearPredictionProfit]: '6m',
  [PredictionScreenerEnum.initialPriceVSCurrent]: 'Initial Price vs Current Price'
};

export const predictionScreenerTitles = (key: string) => {
  const value = predictionScreenerTitlesConsts[key as any];

  if (typeof value === 'function') {
    return value();
  }

  return value;
};

export const predictionScreenerReqKeys = {
  [PredictionScreenerEnum.priceUSD]: {
    min: 'min_price_usd',
    max: 'max_price_usd',
  },
  [PredictionScreenerEnum.priceBTC]: {
    min: 'min_price_btc',
    max: 'max_price_btc',
  },
  [PredictionScreenerEnum.dailyChangeUSD]: {
    min: 'min_daily_change_usd',
    max: 'max_daily_change_usd',
  },
  [PredictionScreenerEnum.dailyChangeBTC]: {
    min: 'min_daily_change_btc',
    max: 'max_daily_change_btc',
  },
  [PredictionScreenerEnum.marketCapUSD]: {
    min: 'min_market_cap_usd',
    max: 'max_market_cap_usd',
  },
  [PredictionScreenerEnum.dailyPredictionStrength]: {
    min: 'min_daily_prediction_strength',
    max: 'max_daily_prediction_strength',
  },
  [PredictionScreenerEnum.dailyPredictionRate]: {
    min: 'min_daily_prediction_rate',
    max: 'max_daily_prediction_rate',
  },
  [PredictionScreenerEnum.cryptoindexRating]: {
    min: 'min_crypto_index',
    max: 'max_crypto_index',
  },
  [PredictionScreenerEnum.coinMarketCapRating]: {
    min: 'min_crypto_index_rating',
    max: 'max_crypto_index_rating',
  },
  [PredictionScreenerEnum.dailyPredictionProfitUSD]: {
    min: 'min_daily_prediction_profit_usd',
    max: 'max_daily_prediction_profit_usd',
  },
  [PredictionScreenerEnum.initialPriceVSCurrent]: {
    min: 'min_initialPriceVSCurrent',
    max: 'max_initialPriceVSCurrent',
  },
  [PredictionScreenerEnum.profitMonthsCount]: 'min_profit_months_count',
};

export enum SortTypeEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}
