import * as React from 'react';
import DifferenceStrength from '../components/difference-strength/v-difference-strength';
import ProfitPercentView from '../components/profit-percent/v-profit-percent';
import TableView, {
  TableColumnView,
  TableHeaderRowView,
  TableHeaderView,
  TableRowView,
} from '../components/table/v-table';
import { useHistory, useLocation } from 'react-router-dom';
import {
  predictionScreenerTitles,
  PredictionScreenerEnum,
  SortTypeEnum,
} from '../api/enums';
import cn from 'classnames';
import { debounce } from 'lodash';
import { updateSearch } from '../api/helpers';
import * as qs from 'qs';
import Input from '../components/input';

function addCommas(nStr) {
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? '.' + x[1] : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ' ' + '$2');
  }
  return x1 + x2;
}

const TableHeaderItem: React.FC<
  { [key: string]: any } & {
    item: PredictionScreenerEnum;
  }
> = ({ item, children, className, ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  const {
    dailyChangeUSD,
    dailyPredictionStrength,
    dailyPredictionRate,
    dailyPredictionProfit,
    monthlyPredictionProfit,
    quarterPredictionProfit,
    halfYearPredictionProfit,
    dailyChangeBTC,
    cryptoindexRating,
    profitMonthsCount,
  } = PredictionScreenerEnum;
  const { DESC, ASC } = SortTypeEnum;

  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  const handleClick = React.useCallback(
    debounce(
      (sort_by: string, order_by: string = SortTypeEnum.DESC) =>
        updateSearch(
          { sortBy: sort_by, orderBy: order_by },
          history,
          location.search,
        ),
      300,
    ),
    [],
  );

  return (
    <TableHeaderRowView
      {...rest}
      className={cn(
        className,
        'pointer',
        {
          'text-center': [
            cryptoindexRating,
            dailyChangeBTC,
            dailyChangeUSD,
            dailyPredictionStrength,
            dailyPredictionRate,
            profitMonthsCount,
            dailyPredictionProfit,
            monthlyPredictionProfit,
            quarterPredictionProfit,
            halfYearPredictionProfit,
          ].includes(item),
        },
        { 'v-prediction-screener__selected': search.sortBy === item },
        {
          'v-prediction-screener__triangle-desc':
            search.sortBy === item && search.orderBy === DESC,
        },
        {
          'v-prediction-screener__triangle-asc':
            search.sortBy === item && search.orderBy === ASC,
        },
      )}
      onClick={() =>
        handleClick(
          item,
          search.sortBy === item && search.orderBy === DESC ? ASC : DESC,
        )
      }
    >
      <span className="relative">
        {children || predictionScreenerTitles(item)}
      </span>
    </TableHeaderRowView>
  );
};

const TableHeader: React.FC<any> = ({
  searchByName,
  setSearchByName,
  metrics,
}) => {
  const {
    dailyPredictionProfit,
    monthlyPredictionProfit,
    quarterPredictionProfit,
    halfYearPredictionProfit,
    coinMarketCapRating,
    marketCapBTC,
    dailyPredictionProfitUSD,
    initialPriceVSCurrent,
  } = PredictionScreenerEnum;
  const predictionProfitPeriods = [
    dailyPredictionProfit,
    monthlyPredictionProfit,
    quarterPredictionProfit,
    halfYearPredictionProfit,
  ];

  const tableHeaderItems = Object.values(PredictionScreenerEnum)
    .filter(
      (i) =>
        ![
          marketCapBTC,
          dailyPredictionProfitUSD,
          dailyPredictionProfit,
          monthlyPredictionProfit,
          quarterPredictionProfit,
          halfYearPredictionProfit,
          coinMarketCapRating,
        ].includes(i),
    )
    .filter((i) => {
      return metrics[i]?.checked;
    });
  return (
    <TableHeaderView>
      <TableColumnView className="table__header-bordered">
        <TableHeaderRowView>#</TableHeaderRowView>
        <TableHeaderRowView>
          <Input
            value={searchByName}
            type="search"
            className="ml-28"
            placeholder="Name"
            onChange={(e) => setSearchByName(e.target.value)}
          />
        </TableHeaderRowView>
        {tableHeaderItems.map((item, idx) => (
          <TableHeaderItem item={item} key={idx} className="text-center" />
        ))}
        {metrics.dailyPredictionProfitUSD['checked'] && (
          <TableHeaderItem
            item={dailyPredictionProfitUSD}
            className="text-center"
          />
        )}
        {metrics.predictionProfit['checked'] &&
          Object.values({
            ...metrics.predictionProfit,
            checked: undefined,
          }).some(Boolean) && (
            <TableHeaderRowView
              className="text-center"
              colSpan={predictionProfitPeriods.length}
            >
              Prediction Profit (BTC)
            </TableHeaderRowView>
          )}
      </TableColumnView>
      <TableColumnView>
        {[...tableHeaderItems, '', ''].map((_, idx) => (
          <TableHeaderRowView
            key={`table-header-second-row-${idx}`}
          ></TableHeaderRowView>
        ))}
        {metrics.dailyPredictionProfitUSD['checked'] && (
          <TableHeaderItem
            item={dailyPredictionProfit}
            className="text-center"
          />
        )}
        {metrics.predictionProfit['checked'] &&
          predictionProfitPeriods.map(
            (period, idx) =>
              metrics.predictionProfit[period] && (
                <TableHeaderItem
                  key={`prediction-profit-period-${idx}`}
                  item={period}
                >
                  {predictionScreenerTitles(period)}
                </TableHeaderItem>
              ),
          )}
      </TableColumnView>
    </TableHeaderView>
  );
};
const IconView = ({ icon, text, ticker }) => (
  <div className="v-prediction-screener__wrapper">
    <img
      src={`img/coins/${icon.toLowerCase().replace(' ', '-')}.png`}
      className="v-prediction-screener__icon"
    />
    {text}
    <span>{ticker}</span>
  </div>
);

const TableItem: React.FC<{ data: any; index: number } & any> = ({
  data,
  index,
  metrics,
}) => {
  const history = useHistory();

  const {
    priceUSD,
    dailyChangeUSD,
    dailyPredictionStrength,
    dailyPredictionRate,
    dailyChangeBTC,
    priceBTC,
    predictionProfit,
    profitMonthsCount,
    marketCapUSD,
    dailyPredictionProfitUSD,
    cryptoindexRating,
    initialPriceVSCurrent,
  } = metrics;

  return (
    <TableColumnView
      onClick={() =>
        data.ticker !== 'BTC' && history.push(`/coin/${data.ticker}`)
      }
    >
      <TableRowView>{index + 1}</TableRowView>
      <TableRowView>
        <IconView
          text={data.title}
          ticker={data.ticker}
          icon={data.currencyId}
        />
      </TableRowView>
      {cryptoindexRating.checked && (
        <TableRowView align="center">{data?.cryptoindexRating}</TableRowView>
      )}
      {priceUSD.checked && (
        <TableRowView>{data.priceUSD && `$${data.priceUSD}`}</TableRowView>
      )}
      {priceBTC.checked && <TableRowView>{data?.priceBTC}</TableRowView>}
      {dailyChangeUSD.checked && (
        <TableRowView align="center">
          {data.dailyChangeUSD && (
            <ProfitPercentView percent={data.dailyChangeUSD} />
          )}
        </TableRowView>
      )}
      {dailyChangeBTC.checked && (
        <TableRowView align="center">
          {data.dailyChangeBTC && (
            <ProfitPercentView percent={data.dailyChangeBTC} />
          )}
        </TableRowView>
      )}
      {marketCapUSD.checked && (
        <TableRowView>
          {data.marketCapUSD && `$ ${addCommas(data.marketCapUSD.toString())}`}
        </TableRowView>
      )}
      {dailyPredictionStrength.checked && (
        <TableRowView
          style={{ width: '165px', paddingLeft: '5px', paddingRight: '5px' }}
        >
          {data.ticker !== 'BTC' &&
            (data.dailyPredictionStrength ? (
              <DifferenceStrength
                type={
                  data.dailyPredictionStrength === 0
                    ? 'neutral'
                    : data.dailyPredictionStrength > 0
                      ? 'bullish'
                      : data.dailyPredictionStrength === -1000
                        ? 'locked'
                        : 'bearish'
                }
                percent={Math.abs(data.dailyPredictionStrength)}
              />
            ) : (
              <DifferenceStrength type="pending" />
            ))}
        </TableRowView>
      )}
      {/* <TableRowView
        style={{ width: "165px", paddingLeft: "5px", paddingRight: "5px" }}
      >
        <DifferenceStrength
          type={
            data.monthlyProfit === 0
              ? "neutral"
              : data.monthlyProfit > 0
              ? "bullish"
              : "bearish"
          }
          percent={data.monthlyPrediction}
        />
      </TableRowView> */}
      {dailyPredictionRate.checked && (
        <TableRowView align="center">
          {data.dailyPredictionRate && `${data.dailyPredictionRate}%`}
        </TableRowView>
      )}
      {profitMonthsCount.checked && (
        <TableRowView align="center">{data?.profitMonthsCount}</TableRowView>
      )}
      {initialPriceVSCurrent?.checked && (
        
        <TableRowView align="center">
          {
          
          data.ticker !== 'BTC' && ((data.dailyPredictionStrength && data.dailyPredictionStrength !== -1000) ? 
          (
            typeof data.initialPriceVSCurrent === 'number' && (
              <ProfitPercentView percent={data.initialPriceVSCurrent} />
            )
          ) :
          <DifferenceStrength type="locked_field" />
          )
          }
        </TableRowView>
      )}
      {dailyPredictionProfitUSD.checked && (
        <TableRowView align="center">
          {data.dailyPredictionProfitUSD && (
            <ProfitPercentView percent={data.dailyPredictionProfitUSD} />
          )}
        </TableRowView>
      )}
      {predictionProfit.checked && (
        <>
          {predictionProfit['dailyPredictionProfit'] && (
            <TableRowView align="center">
              {data.dailyPredictionProfit && (
                <ProfitPercentView percent={data.dailyPredictionProfit} />
              )}
            </TableRowView>
          )}
          {predictionProfit['monthlyPredictionProfit'] && (
            <TableRowView align="center">
              {data.monthlyPredictionProfit && (
                <ProfitPercentView percent={data.monthlyPredictionProfit} />
              )}
            </TableRowView>
          )}
          {predictionProfit['quarterPredictionProfit'] && (
            <TableRowView align="center">
              {data.quarterPredictionProfit && (
                <ProfitPercentView percent={data.quarterPredictionProfit} />
              )}
            </TableRowView>
          )}
          {predictionProfit['halfYearPredictionProfit'] && (
            <TableRowView align="center">
              {data.halfYearPredictionProfit && (
                <ProfitPercentView percent={data.halfYearPredictionProfit} />
              )}
            </TableRowView>
          )}
        </>
      )}
    </TableColumnView>
  );
};

const PredictionScreener: React.FC<{ data: any[] } & any> = ({
  data,
  searchByName,
  setSearchByName,
  metrics,
}) => (
  <TableView>
    <TableHeader {...{ searchByName, setSearchByName, metrics }} />
    <tbody>
      {data.map((item, index) => (
        <TableItem
          metrics={metrics}
          key={item.currencyId}
          data={item}
          index={index}
        />
      ))}
    </tbody>
  </TableView>
);

export default PredictionScreener;
