import * as React from 'react';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

interface ITextProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {}

const Text: React.FC<ITextProps> = ({ children, ...rest }) => {
  return <span {...rest}>{children}</span>;
};

export default Text;
