import * as React from 'react';

import './style.sass';



const SubmitButton: React.FC<{render: (onClose?: (val: boolean) => void) => React.ReactNode, onSubmit: (val: any) => void, header?: React.FC}> = ({render, header: Header, onSubmit, children, ...rest}) => {
	return (
		<div className='submit-button__wrapper'>
			<div className='submit-button__content-wrapper'>
				<div className='submit-button__header'>{Header && <Header />}</div>
				<div className='submit-button__content'>{render()}</div>
			</div>
			<button type='submit' onClick={onSubmit} className='submit-button__button' {...rest}>{children}</button>
		</div>
	)
}

export default SubmitButton
