import * as React from 'react';

import './style.scss';

export const validateNumber = (value: string, max = 8): boolean => {
  const regex = `^(?!00+$)^(?!-0+$)^(?!-\\.+$)\\-?\\d*\\.?\\d{0,${max}}$`;
  return new RegExp(regex, 'g').test(value);
};

export const handleInputChange = (
  oldVal: string,
  val: string,
  restriction?: number,
) => {
  const value = val.replace(/[^0-9.-]+/g, '');
  if (validateNumber(value, restriction)) {
    if (value.startsWith('.')) {
      return '0' + value;
    }
    return value;
  }

  return oldVal;
};

const Input: React.FC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & { type?: 'search' | 'input' }
> = ({ className, type = 'input', ...rest }) => (
  <input
    {...rest}
    className={`${type === 'search' ? 'input-search' : 'input'} ${className}`}
  />
);

export default Input;
