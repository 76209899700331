import moment, { Moment } from 'moment';
import qs from 'qs';
import axios from 'axios';
import * as Sentry from '@sentry/react';

// change to /api when build
// const API_URL = 'https://cryptoforecast.io/api';
const API_URL = process.env.REACT_APP_API
const API_URL_DEV = process.env.REACT_APP_API

const setToken = async () => {
  const token = await localStorage.getItem('token')
  axios.defaults.headers.common["Authorization"] = token ? `Bearer ${token}` : ''
}

setToken()

export const getCurrencies = () =>
  fetch(`${API_URL}/currencies`)
    .then((res) => res.json())
    .then((res) => res.result);

export const getPredictionScreenerData = (parametrs: { [key: string]: any }) =>
  fetch(
    `${API_URL}/prediction-screener${qs.stringify(parametrs, {
      addQueryPrefix: true,
    })}`,
    {
      headers: {
        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
      },
    },
  )
    .then((res) => res.json())
    .then((res) => {
      if (
        res &&
        res.items &&
        res.items.some((item: { dailyPredictionStrength: number; }) => item.dailyPredictionStrength === -1000)
      ) {
        // localStorage.removeItem('token');
      }
      if(!res || !res.items )
        Sentry.captureException(res);
      return res;
    });

export const getTotalProfitStatistics = (parametrs: any) =>
  fetch(
    `${API_URL}/total-profit-statistics${qs.stringify(parametrs, {
      addQueryPrefix: true,
    })}`,
  )
    .then((res) => res.json())
    .then((res) => res.items);

export const getCurrencyInfo = (currency: string) =>
  fetch(`${API_URL}/currency/${currency}`, {
    headers: {
      Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
    },
  }).then((res) => res.json());

export const getCurrencyDailyPredictions = (
  currency: string,
  parametrs?: { [key: string]: any },
) =>
  fetch(
    `${API_URL}/currency/${currency}/daily_predictions${qs.stringify(
      parametrs,
      { addQueryPrefix: true },
    )}`,
  )
    .then((res) => res.json())
    .then((res) => res.items);

// const epicReq = getPredictionScreenerData({}).then(({ items }) => {
//   const res = items.map(({ ticker, title }) => getCurrencyDailyPredictions(ticker)
//     .then(prs => {
//       prs.forEach(element => {
//         // console.log('coin ok ', ticker)
//         if (element.profit > 100) {
//           console.log(`Warning: ${title} so profitly!`)
//           return;
//         }
//         if (element.profit > 1000) {
//           console.log(`Warning: ${title} hight profitly!`)
//           return;
//         }
//       });
//     }))
// })

export const getPredictionHistory = (
  currency: string,
  {
    start_date = moment(new Date()).subtract(31, 'days').unix() * 1000,
    end_date = moment(new Date()).subtract(1, 'days').unix() * 1000,
    sort_type = 'DESC',
    sort_by,
  }: {
    start_date?: number;
    end_date?: number;
    sort_type?: string;
    sort_by?: string;
  },
) => {
  // console.log(start_date, end_date)
  start_date = +start_date;
  end_date = +end_date + 40900000;

  const initialdate =
    moment(new Date(start_date)).subtract(30, 'days').unix() * 1000;
  const maxEndDate = moment(new Date()).subtract(1, 'days').unix() * 1000;

  // console.log('after', { from: start_date, to: maxEndDate >= end_date ? end_date : maxEndDate })
  // 1585083600000 1585170000000

  return fetch('https://apitest.dexpa.io/factors/v1/list', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      coin: currency,
      timeframe: 'D1',
      itemGroups: ['pr'],
      items: [
        {
          group: 'tr',
          code: 'TR_TOT_AVG_PR_BTC',
          datatype: 'num',
        },
      ],
      from: initialdate,
      to: maxEndDate >= end_date ? end_date : maxEndDate,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.error) {
        return Promise.reject(res.error);
      }

      return res;
    })
    .then((res) => res.response)
    .then((res) => {
      let index = 0;

      for (let i = 0; i < res.length; i++) {
        if (res[i].data.pr) {
          index = i;
          break;
        }
      }
      return res.slice(index);
    })
    .then((res) =>
      res.map((item: { timestamp: any; data: { pr: { predict_1_day_lgb: undefined; }; tr: { TR_TOT_AVG_PR_BTC: undefined; }; }; }) => ({
        timestamp: item.timestamp,
        daily_prediction: item?.data?.pr?.predict_1_day_lgb ?? 0.5,
        price: item?.data?.tr?.TR_TOT_AVG_PR_BTC ?? 0,
        status:
          item?.data?.pr?.predict_1_day_lgb !== undefined &&
          item?.data?.tr?.TR_TOT_AVG_PR_BTC !== undefined,
      })),
    )
    .then((res) => {
      if (res[0].price === 0) {
        for (let i = 1; i < res.length; i++) {
          if (res[i].price > 0) {
            res[0].price = res[i].price;
            break;
          }
        }
      }

      const result: any[] = [];

      for (let i = 1; i < res.length; i++) {
        const current_pr = res[i];
        const previous_pr = res[i - 1];

        if (current_pr.price === 0) {
          current_pr.price = previous_pr.price;
        }

        result.push({
          timestamp: current_pr.timestamp,
          daily_prediction: current_pr.daily_prediction,
          current_price: current_pr.price,
          previous_price: previous_pr.price,
          has_pr: current_pr.status,
          ticker: currency,
        });
      }

      return result;
    })
    .then((res) =>
      res.map((mergedPrediction) => {
        const timestamp = mergedPrediction.timestamp;
        const prediction = mergedPrediction.daily_prediction;
        const currentPrice = mergedPrediction.current_price;
        const previousPrice = mergedPrediction.previous_price;

        const priceDifference = currentPrice - previousPrice;
        const predictionIsCorrect =
          (priceDifference < 0 && prediction < 0.5) ||
          (priceDifference > 0 && prediction > 0.5) ||
          (priceDifference == 0 && prediction == 0.5);
        const neutralTrend =
          (priceDifference == 0 && prediction == 0.5) ||
          !mergedPrediction.has_pr;

        const multiplier = predictionIsCorrect ? 1 : -1;

        let absProfit =
          currentPrice != 0
            ? Math.abs((priceDifference * 100) / currentPrice)
            : 0;
        absProfit = !neutralTrend ? absProfit : 0;
        absProfit = absProfit * multiplier;

        if (Math.ceil(absProfit) === -100 || Math.ceil(absProfit) === 100) {
          absProfit = 0;
        }

        return {
          timestamp: timestamp,
          profit: absProfit,
          predictionStart: convertExponentialToDecimal(previousPrice),
          predictionEnd: convertExponentialToDecimal(currentPrice),
          accuracy: neutralTrend ? 0 : multiplier,
          predictionStrength: (prediction * 2 - 1) * 100,
          ticker: mergedPrediction.ticker,
        };
      }),
    )
    .then((predictions) => {
      for (let i = 29; i < predictions.length; i++) {
        predictions[i]['rate'] = 0;

        for (let j = i - 29; j <= i; j++) {
          predictions[i]['rate'] += predictions[j].accuracy == 1 ? 1 : 0;
        }

        predictions[i]['rate'] = (predictions[i]['rate'] / 30) * 100;
      }

      return predictions.slice(29) as any[];
    })
    .then((res) =>
      res
        .map((item) => ({
          ...item,
          profit: Math.floor(item.profit * 100) / 100,
          predictionStrength:
            item.predictionStrength > 0
              ? Math.ceil(item.predictionStrength)
              : Math.floor(item.predictionStrength),
          predictionRate: Math.round(item.rate),
          accuracy:
            item.accuracy === 0 ? null : item.accuracy === 1 ? true : false,
        }))
        .reverse(),
    )
    .then((res) => {
      let newRes = res;
      if (sort_by) {
        newRes = newRes.sort(
          (a, b) => (a[sort_by] || -100) - (b[sort_by] || -100),
        );
      }
      if (sort_by && sort_type == 'DESC') {
        newRes = newRes.reverse();
      }

      // console.log(newRes)

      return newRes;
    });
};

export function convertExponentialToDecimal(
  exponentialNumber: number,
): number | string {
  // sanity check - is it exponential number
  const str = exponentialNumber.toString();
  if (str.includes('e')) {
    const exponent = parseInt(str.split('-')[1], 10);
    // Unfortunately I can not return 1e-8 as 0.00000001, because even if I call parseFloat() on it,
    // it will still return the exponential representation
    // So I have to use .toFixed()
    const res = exponentialNumber.toFixed(exponent + 1);
    let index = res.length;
    for (let i = res.length - 1; i > 0; i--) {
      if (res[i] === '0') {
        index = i;
      } else {
        break;
      }
    }

    return res.slice(0, index);
  } else {
    return exponentialNumber;
  }
}

export const registration = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${API_URL_DEV}/create`, { email, password })
    if (response.status == 200) {
      await localStorage.setItem('token', response.data.accessToken);
      await setToken()
      return true;
    }
    else
      return "Something wents wrong, try later";
  } catch (e) {
    console.log(e)
    Sentry.captureException(e);
    return e.response.data.status || e;
  }
}

export const authorization = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${API_URL_DEV}/login`, { email, password })
    if (response.status == 200) {
      await localStorage.setItem('token', response.data.accessToken);
      await setToken()
      return true
    }
    else
      return "Something wents wrong, try later";
  } catch (e) {
    Sentry.captureException(e);    
    return e.response.data.status || e;
  }
}

export const resetPassword = async (email: string) => {
  try {
    const response = await axios.post(`${API_URL_DEV}/password/reset`, {email})
    if (response.status == 200) {
      return true;
    }
    return "Something wents wrong, try later";
  } catch (e) {
    Sentry.captureException(e);
    return e.response.data.status
  }
}

export const getProfileInfo = async () => {
  try {
    const response = await axios.get(`${API_URL_DEV}/me`)
    if (response.status == 200 && response.data != []) {
      return {
        email: response.data.email,
        free_license: response.data.free_license,
        date: response.data.date
      }
    } else
      return false
  }
  catch (e) {
    Sentry.captureException(e);
    return false
  }
}

export const setNewPassword = async (resetToken: any, password: string) =>{
  try {
    const response = await axios.post(`${API_URL_DEV}/password/update`, {resetToken, password})      
    return response
  } catch (e) {
    if (e.response.status === 401) {
      localStorage.removeItem('token')
    }
    Sentry.captureException(e);
    return e.response.data.status
  }
}

export const logout = async () => {
  try {
    const response = await axios.post(`${API_URL_DEV}/logout`)
    localStorage.removeItem('token')
  } catch (e) {
    if (e.response.status === 401) {
      localStorage.removeItem('token')
    }
    Sentry.captureException(e);
  }
}