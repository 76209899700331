import cn from 'classnames';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import '../assets/style/v-auth.sass';
import { authorization, setNewPassword } from '../api/api';
import logo from '../assets/img/logo-registration.svg'
import ButtonLoader from '../components/button';

/*
    Content of the user set a new password window with yup verification and get url params
*/

interface IReg {
    password: string;
    confirmPassword: string;
}
const NewPasswordView: React.FC = () => {
    const [values, setValues] = React.useState<IReg>({ password: "", confirmPassword: "" });
    const [message, setMessage] = React.useState<{ password: string, confirmPassword: string, error: string }>({ password: "", confirmPassword: "", error: "" });
    const [loading, setLoading] = React.useState<boolean>(false)
    let history = useHistory();
    const search = useLocation().search;
    const resetPasswordToken = new URLSearchParams(search).get('resetPasswordToken');

    let validPassword = yup.object().shape({
        password: yup.string().min(6)
    });

    const validate = () => {
        if (values.password !== "" && values.confirmPassword !== '')
            return true;
        else {
            return false;
        }
    }

    const error = async () => {
        await setMessage({ password: "", confirmPassword: "", error: "" })
        if (values.password !== "" && !validPassword.isValidSync(values)) {
            setMessage({ ...message, password: "Password is too short (minimum is 6 characters)" })
        }
        if (values.confirmPassword !== "" && values.password !== values.confirmPassword) {
            setMessage({ ...message, confirmPassword: "Your password and confirmation password do not match" })
        }
    }

    React.useEffect(() => {
        error()
    }, [values]);

    return (
        <div className='flex items-center flex-col v-modal-auth'>
            <div
                className='flex pointer v-modal-auth__logo'
                onClick={() => history.push('/')}
            >
                <img
                    src={logo}
                />
            </div>
            <div className="flex items-center h-full flex-col justify-center">
                <h4 className="mb-40">Set new password</h4>
                <input
                    type='password'
                    placeholder='Enter password'
                    className={!message.password ? 'input-text' : 'input-text input-text__invalid'}
                    value={values.password}
                    onChange={(e) => {
                        setMessage({ ...message, password: '', error: '' })
                        setValues({ ...values, password: e.target.value })
                    }}
                />
                {message.password &&
                    <div className='v-modal-auth__error-message'>{message.password}</div>}
                <input
                    type='password'
                    placeholder='Confirm password'
                    className={!message.confirmPassword ? 'input-text' : 'input-text input-text__invalid'}
                    value={values.confirmPassword}
                    formNoValidate
                    onChange={(e) => {
                        setMessage({ ...message, confirmPassword: '', error: '' })
                        setValues({ ...values, confirmPassword: e.target.value })
                    }}
                />
                {message.confirmPassword &&
                    <div className='v-modal-auth__error-message'>{message.confirmPassword}</div>}
                <ButtonLoader
                    disabled={loading}
                    opacity={validate() && !loading ? '1' : '0.4'}
                    size={'large'}
                    onClick={async () => {
                        setLoading(true)
                        error()
                        if (validate() && validPassword.isValidSync(values) && values.password === values.confirmPassword) {
                            let response = await setNewPassword(resetPasswordToken, values.password)
                            if (response.status == 200) {
                                await authorization(response.data.email, values.password)
                                history.push('/')
                            }
                            else
                                setMessage({ ...message, error: response });
                        }
                        setLoading(false)
                    }}
                    type="button"
                    isLoad={loading}
                    children="Done" />
                {message.error &&
                    <div className='v-modal-auth__error-message'>{message.error}</div>}
                <div className='line' />
                <div>
                    <a href="#" onClick={() =>  history.push('/auth') } className='mr-25'>Log in</a>
                    <a href="#" onClick={() =>  history.push('/registration')}>Sign up for an account</a>
                </div>
            </div>
        </div>
    );
};

export default NewPasswordView;
