import * as React from "react";

export const TableHeaderView: React.FC<React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableSectionElement>,
  HTMLTableSectionElement
>> = ({ children, ...rest }) => {
  return <thead {...rest}>{children}</thead>;
};

export const TableHeaderRowView: React.FC<React.DetailedHTMLProps<
  React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
  HTMLTableHeaderCellElement
>> = ({ children, ...rest }) => {
  return <th {...rest}>{children}</th>;
};

export const TableColumnView: React.FC<React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
>> = ({ children, ...rest }) => {
  return <tr {...rest}>{children}</tr>;
};

export const TableRowView: React.FC<React.DetailedHTMLProps<
  React.TdHTMLAttributes<HTMLTableDataCellElement>,
  HTMLTableDataCellElement
>> = ({ children, ...rest }) => {
  return <td {...rest}>{children}</td>;
};

const TableView: React.FC<React.DetailedHTMLProps<
  React.TableHTMLAttributes<HTMLTableElement>,
  HTMLTableElement
>> = ({ children, className, ...rest }) => {
  return <table {...rest} className={`table ${className}`}>{children}</table>;
};

export default TableView;
