import { DetailedHTMLProps, HTMLAttributes, createElement } from 'react';

interface ITitleProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
}

const Title: React.FC<ITitleProps> = ({ children, level = 1, ...rest }) => {
  return createElement(`h${level}`, rest, children);
};

export default Title;
