import ReactModal from 'react-modal';
import classnames from 'classnames';
import React from 'react';

import './style.scss';

ReactModal.setAppElement(document.getElementById('root') as HTMLElement);

interface ModalProps
  extends Omit<
    ReactModal.Props,
    | 'className'
    | 'overlayClassName'
    | 'bodyOpenClassName'
    | 'htmlOpenClassName'
    | 'portalClassName'
  > {
  contentClassName?: string;
  overlayClassName?: string;
}

const Modal: React.FC<ModalProps> = ({
  children,
  closeTimeoutMS = 200,
  contentClassName = 'modal-content--right',
  overlayClassName,
  ...rest
}) => (
  <ReactModal
    {...rest}
    className={classnames('modal-content', contentClassName)}
    overlayClassName={classnames('modal-overlay', overlayClassName)}
    closeTimeoutMS={closeTimeoutMS}
    ariaHideApp={false}
  >
    {children}
  </ReactModal>
);

export default Modal;
